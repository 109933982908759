import React, { useState } from "react";
import { Formik, Form } from "formik";
import axios from "../../../../../axios";
import LoadingSpinner from "../../../../../LoadingSpinner/LoadingSpinner";
import { emitTaskNotification } from "../../../../../util/io";

const WaitingOffer = ({
  rejected,
  taskId,
  setStatus,
  offers,
  setTask,
  task,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const initialValues = {
    paid: "",
  };

  const onSubmit = async (values) => {
    try {
      setIsLoading(true);
      if (values.paid === "") {
        alert("Please enter a cost");
        setIsLoading(false);
        return;
      }
      await axios.post(`/task/action/price/${taskId}`, values);
      emitTaskNotification(
        `Task No: ${task.serialNumber} - Offer Submitted`,
        task
      );
      window.location.reload();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (error.response) {
        console.log(error.response.data.err);
      } else {
        console.log(error.message);
      }
    }
  };

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <div className="space-y-2">
      <Formik initialValues={initialValues} onSubmit={onSubmit} className="">
        {({ getFieldProps }) => (
          <Form className="flex items-end justify-between">
            <div className="w-1/3">
              <label htmlFor="paid" className="text-gray-400 font-semibold">
                Client Price
              </label>
              <input type="number" {...getFieldProps("paid")} />
            </div>
            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded active:scale-95"
            >
              Submit
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default WaitingOffer;
