import React from "react";
import { GrInProgress } from "react-icons/gr";
import { IoMdCloseCircle } from "react-icons/io";
import { MdDone, MdDoneAll, MdOutlineAssignmentInd, MdOutlinePendingActions, MdOutlinePriceCheck, MdPendingActions } from "react-icons/md";
import { TiCancel } from "react-icons/ti";
import { TbSettingsPause } from "react-icons/tb";
import { AiOutlineLike } from "react-icons/ai";
import { LuFileSearch } from "react-icons/lu";
import { FaRegEdit, FaTasks } from "react-icons/fa";

const StatusView = ({tasksCount,statusStatistics}) => {

    return (
        <>
            <div className="flex items-center justify-between my-4">
                <div className="grid grid-cols-5 gap-2 px-6">
                    <div className="col-span-2 bg-white rounded drop-shadow flex items-center space-x-2 px-4 py-2.5">
                        <FaTasks className="bg-blue-100 text-blue-500 w-10 h-10 p-2 rounded" />
                        <div>
                        <h6 className="m-0 p-0 text-sm">Tasks Count</h6>
                        </div>
                        <div className="">
                        <h4 className="font-bold ml-12 my-0 p-0">{tasksCount}</h4>
                        </div>
                    </div>
                    <div className="bg-white rounded drop-shadow flex items-center space-x-2 px-4 py-2.5">
                        <GrInProgress className="bg-yellow-100 text-yellow-500 w-10 h-10 p-2 rounded" />
                        <div>
                        <h6 className="m-0 p-0 text-sm font-semibold">
                            Wiating Offer
                        </h6>
                        <h4 className="font-light ml-1 my-0 p-0">{statusStatistics.waitingOffer}</h4>
                        </div>
                    </div>
                    <div className="bg-white rounded drop-shadow flex items-center space-x-2 px-4 py-2.5">
                        <MdOutlinePriceCheck className="bg-blue-100 text-blue-500 w-10 h-10 p-2 rounded" />
                        <div>
                        <h6 className="m-0 p-0 text-sm font-semibold">
                            Offer Submitted
                        </h6>
                        <h4 className="font-light ml-1 my-0 p-0">{statusStatistics.offerSubmitted}</h4>
                        </div>
                    </div>
                    <div className="bg-white rounded drop-shadow flex items-center space-x-2 px-4 py-2.5">
                        <MdOutlinePendingActions className="bg-orange-500 text-orange-100 w-10 h-10 p-2 rounded" />
                        <div>
                        <h6 className="m-0 p-0 text-sm font-semibold">
                            Pending
                        </h6>
                        <h4 className="font-light ml-1 my-0 p-0">{statusStatistics.pending}</h4>
                        </div>
                    </div>
                    <div className="bg-white rounded drop-shadow flex items-center space-x-2 px-4 py-2.5">
                        <AiOutlineLike className="bg-blue-400 text-blue-100 w-10 h-10 p-2 rounded" />
                        <div>
                        <h6 className="m-0 p-0 text-sm font-semibold">
                            Approved
                        </h6>
                        <h4 className="font-light ml-1 my-0 p-0">{statusStatistics.approved}</h4>
                        </div>
                    </div>
                    <div className="bg-white rounded drop-shadow flex items-center space-x-2 px-4 py-2.5">
                        <MdOutlineAssignmentInd className="bg-purple-100 text-purple-500 w-10 h-10 p-2 rounded" />
                        <div>
                        <h6 className="m-0 p-0 text-sm font-semibold">
                            Assigned
                        </h6>
                        <h4 className="font-light ml-1 my-0 p-0">{statusStatistics.assigned}</h4>
                        </div>
                    </div>
                    <div className="bg-white rounded drop-shadow flex items-center space-x-2 px-4 py-2.5">
                        <TbSettingsPause className="bg-blue-700 text-blue-100 w-10 h-10 p-2 rounded" />
                        <div>
                        <h6 className="m-0 p-0 text-sm font-semibold">
                            On Going
                        </h6>
                        <h4 className="font-light ml-1 my-0 p-0">{statusStatistics.onGoing}</h4>
                        </div>
                    </div>
                    <div className="bg-white rounded drop-shadow flex items-center space-x-2 px-4 py-2.5">
                        <MdDone className="bg-green-100 text-green-700 w-10 h-10 p-2 rounded" />
                        <div>
                        <h6 className="m-0 p-0 text-sm font-semibold">
                            Done
                        </h6>
                        <h4 className="font-light ml-1 my-0 p-0">{statusStatistics.done}</h4>
                        </div>
                    </div>
                    <div className="bg-white rounded drop-shadow flex items-center space-x-2 px-4 py-2.5">
                        <LuFileSearch className="bg-yellow-500 text-yellow-100 w-10 h-10 p-2 rounded" />
                        <div>
                        <h6 className="m-0 p-0 text-sm font-semibold">
                            In Review
                        </h6>
                        <h4 className="font-light ml-1 my-0 p-0">{statusStatistics.inReview}</h4>
                        </div>
                    </div>
                    <div className="bg-white rounded drop-shadow flex items-center space-x-2 px-4 py-2.5">
                        <MdDoneAll className="bg-green-700 text-green-100 w-10 h-10 p-2 rounded" />
                        <div>
                        <h6 className="m-0 p-0 text-sm font-semibold">
                            Delivered
                        </h6>
                        <h4 className="font-light ml-1 my-0 p-0">{statusStatistics.delivered}</h4>
                        </div>
                    </div>
                    <div className="bg-white rounded drop-shadow flex items-center space-x-2 px-4 py-2.5">
                        <FaRegEdit className="bg-purple-600 text-purple-100 w-10 h-10 p-2 rounded" />
                        <div>
                        <h6 className="m-0 p-0 text-sm font-semibold">
                            Edit
                        </h6>
                        <h4 className="font-light ml-1 my-0 p-0">{statusStatistics.edit}</h4>
                        </div>
                    </div>
                    <div className="bg-white rounded drop-shadow flex items-center space-x-2 px-4 py-2.5">
                        <TiCancel className="bg-gray-700 text-gray-100 w-10 h-10 p-2 rounded" />
                        <div>
                        <h6 className="m-0 p-0 text-sm font-semibold">
                            Not Available
                        </h6>
                        <h4 className="font-light ml-1 my-0 p-0">{statusStatistics.notAvailable}</h4>
                        </div>
                    </div>
                    <div className="bg-white rounded drop-shadow flex items-center space-x-2 px-4 py-2.5">
                        <IoMdCloseCircle className="bg-red-100 text-red-500 w-10 h-10 p-2 rounded" />
                        <div>
                        <h6 className="m-0 p-0 text-sm font-semibold">
                            Rejected
                        </h6>
                        <h4 className="font-light ml-1 my-0 p-0">{statusStatistics.rejected}</h4>
                        </div>
                    </div>
                    <div className="bg-white rounded drop-shadow flex items-center space-x-2 px-4 py-2.5">
                        <IoMdCloseCircle className="bg-red-700 text-red-100 w-10 h-10 p-2 rounded" />
                        <div>
                        <h6 className="m-0 p-0 text-sm font-semibold">
                            Cancel
                        </h6>
                        <h4 className="font-light ml-1 my-0 p-0">{statusStatistics.cancel}</h4>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default StatusView;