import React, { useEffect, useReducer, useState } from "react";
import { validate, VALIDATOR_MINLENGTH } from "../../../../util/validators";
import axios from "axios";
import LoadingSpinner from "../../../../LoadingSpinner/LoadingSpinner";
import { useNavigate, useParams } from "react-router-dom";
import DateFilterComponent from "../../../DateFilter";
import { RiDeleteBinFill } from "react-icons/ri";
import { FaCoins, FaTasks } from "react-icons/fa";
import { GiProfit } from "react-icons/gi";
import { AiOutlineFileDone } from "react-icons/ai";
import TasksTable from "../../../TasksTable/TasksTable";
import instance from "../../../../axios";
import ErrorModal from "../../../../LoadingSpinner/ErrorModal";
import { HiBanknotes } from "react-icons/hi2";

const userNameReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE":
      return {
        ...state,
        value: action.userName,
        isvalid: validate(action.userName, action.validators),
      };
    case "TOUCH":
      return {
        ...state,
        isTouched: true,
      };
    default:
      return state;
  }
};
//fullName validation
const fullNameReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE":
      return {
        ...state,
        value: action.fullName,
        isvalid: validate(action.fullName, action.validators),
      };
    case "TOUCH":
      return {
        ...state,
        isTouched: true,
      };
    default:
      return state;
  }
};
//number validation
const numberReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE":
      return {
        ...state,
        value: action.number,
        isvalid: validate(action.number, action.validators),
      };
    case "TOUCH":
      return {
        ...state,
        isTouched: true,
      };
    default:
      return state;
  }
};
//country validation
const countryReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE":
      return {
        ...state,
        value: action.country,
        isvalid: validate(action.country, action.validators),
      };
    case "TOUCH":
      return {
        ...state,
        isTouched: true,
      };
    default:
      return state;
  }
};
const UserDetails = () => {
  const [commission, setCommission] = useState(0);
  const [tasksCount, setTasksCount] = useState(0);
  const [completedCount, setCompletedCount] = useState(0);
  const [totalGain, setTotalGain] = useState(0);
  const [totalProfit, setTotalProfit] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [limit, setLimit] = useState(30);
  const [page, setPage] = useState(1);
  const [visable, setVisable] = useState(false);
  const [editFull, setEditFull] = useState(false);
  const [editSpeciality, setEditSpeciality] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [user, setUser] = useState([]);
  const [userSpeciality, setUserSpeciality] = useState();
  const [userRole, setUserRole] = useState();
  const [specialityId, setspecialityId] = useState();
  const [specialities, setSpecialities] = useState([]);
  const [countries, setCountries] = useState([]);
  const [userTasks, setUserTasks] = useState([]);
  const [country, setCountry] = useState("");
  const [start, setStart] = useState();
  const [end, setEnd] = useState();
  const [password, setPassword] = useState();
  const navigate = useNavigate();
  let { id } = useParams();
  const [selectedRange, setSelectedRange] = useState({ start, end });

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      try {
        const tasksResponse = await instance.get(`/task`, {
          params: {
            user: id,
            page,
            limit,
            start: selectedRange.start,
            end: selectedRange.end,
          },
        });
        const userResponse = await instance.get(`/user/${id}`);
        const specialityResponse = await instance.get(`/speciality`);
        const countryResponse = await instance.get(`/country/`);
        console.log(tasksResponse.data);
        console.log(userResponse.data);

        setTasksCount(tasksResponse.data.tasksCount);
        setTotalGain(tasksResponse.data.totalGain);
        setTotalProfit(tasksResponse.data.totalProfit);
        setCompletedCount(tasksResponse.data.completedTasksCount);
        setTotalCost(tasksResponse.data.totalCost);
        setUserTasks(tasksResponse.data.tasks);
        setUser(userResponse.data.user);
        if (userResponse.data.user.user_role == "specialistService") {
          setspecialityId(userResponse.data.user.speciality);
          setVisable(true);
        }
        setSpecialities(specialityResponse.data.specialities);
        setCountries(countryResponse.data.countries);
      } catch (error) {
        if (error.response) {
          setError({ type: "error", message: error.response.data.err });
        } else {
          setError({ type: "error", message: error.message });
        }
      } finally {
        setIsLoading(false);
      }
    })();
  }, [page, limit, selectedRange]);

  //userName validation
  const [userNameState, dispatch] = useReducer(userNameReducer, {
    value: user.username,
    isvalid: false,
    isTouched: false,
  });

  const userNameChangeHandler = (event) => {
    dispatch({
      type: "CHANGE",
      userName: event.target.value,
      validators: [VALIDATOR_MINLENGTH(3)],
    });
  };
  const userNameTouchHandler = () => {
    dispatch({
      type: "TOUCH",
    });
  };
  //fullName validation
  const [fullNameState, dispatch2] = useReducer(fullNameReducer, {
    value: user.fullname,
    isvalid: false,
    isTouched: false,
  });

  const fullNameChangeHandler = (event) => {
    dispatch2({
      type: "CHANGE",
      fullName: event.target.value,
      validators: [VALIDATOR_MINLENGTH(3)],
    });
  };
  const fullNameTouchHandler = () => {
    dispatch2({
      type: "TOUCH",
    });
  };
  const countryChangeHandler = (newOne) => {
    setCountry(newOne);
  };
  //Number validation
  const [numberState, dispatclabel] = useReducer(numberReducer, {
    value: user.phone,
    isvalid: false,
    isTouched: false,
  });
  const numberChangeHandler = (event) => {
    dispatclabel({
      type: "CHANGE",
      number: event.target.value,
      validators: [VALIDATOR_MINLENGTH(11)],
    });
  };
  const numbertouchHandler = () => {
    dispatclabel({
      type: "TOUCH",
    });
  };
  //speciality value
  const specialityChangeHandler = (newOne) => {
    setUserSpeciality(newOne);
  };
  const editUserHandler = async (event) => {
    event.preventDefault();
    // send api request to validate data
    setIsLoading(true);
    try {
      setError(null);
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}:5001/api/user/${user?._id}`,
        {
          fullName: fullNameState.value,
          userName: userNameState.value,
          userRole: userRole,
          speciality: userSpeciality,
          country: country,
          phone: numberState.value,
          password: password,
        }
      );
      const responseData = await response;
      if (!(response.statusText === "OK")) {
        throw new Error(responseData.data.message);
      }
      setError(`${responseData.data.message}`);
      setIsLoading(false);
      window.location.reload();
    } catch (err) {
      setIsLoading(false);
      setError(err.message && "SomeThing Went Wrong , Please Try Again .");
    }
  };
  //delete user
  const deleteUserHandler = async () => {
    setIsLoading(true);
    try {
      setError(null);
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}:5001/api/user/${id}`
        //  ,
        //  { headers :{
        //     'Authorization':`Bearer ${token}`
        //   }
        // }
      );
      const responseData = await response;

      setError(responseData.data.message);
      setIsLoading(false);
      window.location.href = "/";
    } catch (err) {
      setIsLoading(false);
      setError(err.message || "SomeThing Went Wrong , Please Try Again .");
    }
  };
  //error message
  const errorHandler = () => {
    setError(null);
    window.location.reload(true);
  };

  const onDateChange = (dates) => {
    const [start, end] = dates;
    setStart(start);
    setEnd(end);
  };

  useEffect(() => {
    if (start && end) {
      setSelectedRange({ start, end });
      setPage(1);
    }
  }, [start, end]);

  const onFirstPage = () => {
    setPage(1);
  };
  const onLastPage = () => {
    setPage(Math.ceil(tasksCount / limit));
  };
  const onNextPage = () => {
    setPage((prev) => prev + 1);
  };
  const onPreviousPage = () => {
    setPage((prev) => prev - 1);
  };

  return isLoading ? (
    <LoadingSpinner asOverlay />
  ) : (
    <div className="flex flex-col w-full p-3 min-h-[calc(100vh-65px)]">
      <ErrorModal message={error} onClear={() => setError(null)} />
      <div className="relative flex items-center justify-between w-full p-1 mb-4">
        {/* <button
          className="absolute top-0 left-0 p-2 text-3xl"
          onClick={() => navigate("/users")}
        >
          <TiArrowBack />
        </button> */}
        <h2 className="text-center text-2xl font-bold lg:text-3xl">
          User Details
        </h2>
        <div>
          <DateFilterComponent
            startDate={start}
            endDate={end}
            onChange={onDateChange}
          />
        </div>
      </div>

      <div className="relative flex items-center justify-betwen border-2 space-x-8 rounded-md shadow p-4 bg-[#F4F7FC]">
        {user.user_role !== "admin" && (
          <button
            className="absolute top-2 right-1.5 w-10 h-10"
            onClick={deleteUserHandler}
          >
            <RiDeleteBinFill className="text-gray-400 w-10 h-10" />
          </button>
        )}

        <div className="w-1/2 grid grid-cols-2 gap-4">
          <div className="flex flex-col w-full">
            <label className="w-full font-bold">Full Name</label>
            <p
              className={
                !editFull ? "ml-2 text-gray-500 font-medium" : "hidden"
              }
            >
              {user.fullname}
            </p>

            {editFull && (
              <input
                type="text"
                placeholder={user.fullname}
                value={fullNameState.value}
                onChange={fullNameChangeHandler}
                onBlur={fullNameTouchHandler}
                isvalid={fullNameState.isvalid.toString()}
                className={`w-11/12 ml-2 rounded-sm p-2 ${
                  !fullNameState.isvalid &&
                  fullNameState.isTouched &&
                  "border-red-500"
                }`}
              />
            )}
          </div>

          <div className="flex flex-col w-full">
            <label className="w-full font-bold">User Name</label>
            <p
              className={
                !editFull ? "ml-2 text-gray-500 font-medium" : "hidden"
              }
            >
              {user.username}
            </p>
            {editFull && (
              <input
                type="text"
                placeholder={user.username}
                value={userNameState.value}
                onChange={userNameChangeHandler}
                onBlur={userNameTouchHandler}
                isvalid={userNameState.isvalid.toString()}
                className={`w-11/12 ml-2 rounded-sm p-2 ${
                  !userNameState.isvalid &&
                  userNameState.isTouched &&
                  "border-red-500"
                }`}
              />
            )}
          </div>

          <div className="flex flex-col w-full">
            <label className="w-full font-bold">Phone</label>
            <p
              className={
                !editFull ? "ml-2 text-gray-500 font-medium" : "hidden"
              }
            >
              {user.phone}
            </p>
            {editFull && (
              <input
                type="number"
                placeholder={user.phone}
                value={numberState.value}
                onChange={numberChangeHandler}
                onBlur={numbertouchHandler}
                isvalid={numberState.isvalid.toString()}
                className={`w-11/12 ml-2 rounded-sm p-2 ${
                  !numberState.isvalid &&
                  numberState.isTouched &&
                  "border-red-500"
                }`}
              />
            )}
          </div>

          <div className="flex flex-col w-full">
            <label className="w-full font-bold">Country</label>
            <p
              className={
                !editFull ? "ml-2 text-gray-500 font-medium" : "hidden"
              }
            >
              {user.country && user.country.countryName}
            </p>
            {editFull && (
              <select
                id="country"
                name="country"
                className="w-11/12 ml-2 rounded-sm p-2"
                value={country}
                onChange={(event) => countryChangeHandler(event.target.value)}
              >
                <option value="" className="text-secondary">
                  Countries
                </option>
                {countries.map((country) => (
                  <option value={country?._id} key={country?._id}>
                    {country.countryName}
                  </option>
                ))}
              </select>
            )}
          </div>

          <div className="flex flex-col w-full">
            <label className="w-full font-bold">User Role</label>
            <p
              className={
                !editFull ? "ml-2 text-gray-500 font-medium" : "hidden"
              }
            >
              {user.user_role}
            </p>
            {editFull && (
              <select
                id="role"
                name="role"
                className="w-11/12 ml-2 rounded-sm p-2"
                value={userRole}
                onChange={(e) => {
                  setUserRole(e.target.value);
                  if (e.target.value == "specialistService") {
                    setVisable(true);
                    setEditSpeciality(true);
                  } else {
                    setVisable(false);
                  }
                }}
              >
                <option value="" className="text-secondary">
                  Role
                </option>
                <option value="admin">Admin</option>
                <option value="customerService">customerService</option>
                <option value="specialistService">specialistService</option>
                <option value="academicReviewer">Academic Reviewer</option>
              </select>
            )}
          </div>

          {visable && (
            <div className="flex flex-col w-full">
              <label className="w-full font-bold">Speciality</label>
              <p
                className={
                  !editFull ? "ml-2 text-gray-500 font-medium" : "hidden"
                }
              >
                {user.speciality && user.speciality.sub_speciality}
              </p>

              {editFull && (
                <select
                  id="speciality"
                  name="speciality"
                  className="w-11/12 ml-2 rounded-sm p-2"
                  value={userSpeciality}
                  onChange={(event) =>
                    specialityChangeHandler(event.target.value)
                  }
                >
                  <option selected disabled value="" className="text-secondary">
                    Select Speciality
                  </option>
                  {specialities.map((speciality, index) => (
                    <optgroup value="" key={index} label={speciality.main}>
                      {speciality.sub.map((sub) => (
                        <option value={sub?._id}>{sub.sub_speciality}</option>
                      ))}
                    </optgroup>
                  ))}
                </select>
              )}
            </div>
          )}

          {editFull && (
            <div className="flex flex-col w-full">
              <label className="w-full font-bold">User Password</label>
              <input
                type="text"
                placeholder="Enter New Password"
                onChange={(e) => setPassword(e.target.value)}
                className={`w-11/12 ml-2 rounded-sm p-2`}
              />
            </div>
          )}

          <div className="col-span-2 flex items-center justify-center space-x-2">
            {!editFull && user.user_role !== "admin" && (
              <button
                type="button"
                className="bg-cyan-600 rounded-sm transition-all hover:bg-cyan-400 text-white px-12 py-1"
                onClick={() => setEditFull(!editFull)}
              >
                Edit
              </button>
            )}
            {editFull && (
              <>
                <button
                  disabled={
                    !fullNameState.isvalid &&
                    !userNameState.isvalid &&
                    !userRole &&
                    !country &&
                    !numberState.isvalid &&
                    !userSpeciality &&
                    !password
                  }
                  type="button"
                  className="bg-green-500 rounded-sm transition-all hover:bg-green-400 text-white px-3 py-1"
                  onClick={editUserHandler}
                >
                  Save
                </button>
                <button
                  type="button"
                  className="bg-red-500 rounded-sm transition-all hover:bg-red-400 text-white px-3 py-1"
                  onClick={() => {
                    setEditFull(false);
                  }}
                >
                  Cancel
                </button>
              </>
            )}
          </div>
        </div>

        <div className="w-1/2 grid grid-cols-2 gap-2">
          <div className="col-span-2 bg-white rounded drop-shadow px-4 py-2.5 flex items-center space-x-2">
            <div className="w-1/2 flex items-center space-x-2">
              <HiBanknotes className="bg-gray-100 text-gray-500 w-10 h-10 p-2 rounded" />
              <div>
                <h6 className="m-0 p-0 text-sm font-semibold">Commission</h6>
                <h4 className="font-light ml-1 my-0 p-0">
                  {commission.toFixed(2)}
                </h4>
              </div>
            </div>
            <div className="w-1/2">
              <p className="p-0 m-0 text-gray-500 font-semibold">
                Enter Commission Per Task
              </p>
              <input
                type="number"
                className="w-full p-0 m-0 text-gray-500 font-semibold"
                onChange={(e) => {
                  setCommission(completedCount * e.target.value);
                }}
              />
            </div>
          </div>
          <div className="bg-white rounded drop-shadow flex items-center space-x-2 px-4 py-2.5">
            <FaTasks className="bg-blue-100 text-blue-500 w-10 h-10 p-2 rounded" />
            <div>
              <h6 className="m-0 p-0 text-sm font-semibold">Tasks Count</h6>
              <h4 className="font-light ml-1 my-0 p-0">{tasksCount}</h4>
            </div>
          </div>
          <div className="bg-white rounded drop-shadow flex items-center space-x-2 px-4 py-2.5">
            <AiOutlineFileDone className="bg-orange-100 text-orange-500 w-10 h-10 p-2 rounded" />
            <div>
              <h6 className="m-0 p-0 text-sm font-semibold">Completed Count</h6>
              <h4 className="font-light ml-1 my-0 p-0">{completedCount}</h4>
            </div>
          </div>
          {user.user_role === "specialistService" ? (
            <div className="bg-white rounded drop-shadow flex items-center space-x-2 px-4 py-2.5">
              <FaCoins className="bg-teal-100 text-teal-500 w-10 h-10 p-2 rounded" />
              <div>
                <h6 className="m-0 p-0 text-sm font-semibold">Total Cost</h6>
                <h4 className="font-light ml-1 my-0 p-0">{totalCost}</h4>
              </div>
            </div>
          ) : user.user_role === "customerService" && (
            <div className="bg-white rounded drop-shadow flex items-center space-x-2 px-4 py-2.5">
              <FaCoins className="bg-teal-100 text-teal-500 w-10 h-10 p-2 rounded" />
              <div>
                <h6 className="m-0 p-0 text-sm font-semibold">Total Gain</h6>
                <h4 className="font-light ml-1 my-0 p-0">{totalGain}</h4>
              </div>
            </div>
          )}

          {user.user_role === "specialistService" || user.user_role === "customerService" ?(<div className="bg-white rounded drop-shadow flex items-center space-x-2 px-4 py-2.5">
            <GiProfit className="bg-green-100 text-green-500 w-10 h-10 p-2 rounded" />
            <div>
              <h6 className="m-0 p-0 text-sm font-semibold">Total Profit</h6>
              <h4 className="font-light ml-1 my-0 p-0">{totalProfit}</h4>
            </div>
          </div>) : ""}
        </div>
      </div>

      <div className="py-3 drop-shadow">
        {userTasks && !userTasks.length == 0 ? (
          <TasksTable
            pagination
            page={page}
            totalPages={Math.ceil(tasksCount / limit)}
            onFirstPage={onFirstPage}
            onLastPage={onLastPage}
            onNextPage={onNextPage}
            onPreviousPage={onPreviousPage}
            tasks={userTasks}
          />
        ) : (
          <div className="row col-12 p-2 text-center">
            <h3 className="text-danger edit-form-lable">
              This User Didn't Do Any Tasks Yet
            </h3>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserDetails;
