import React, { useEffect, useState, useReducer } from "react";
import { validate, VALIDATOR_MINLENGTH } from "../../../../util/validators";
import axios from "axios";
import LoadingSpinner from "../../../../LoadingSpinner/LoadingSpinner";
import ErrorModal from "../../../../LoadingSpinner/ErrorModal";

import { Link, useNavigate, useParams } from "react-router-dom";
import { TiArrowBack } from "react-icons/ti";
import { IoMdRemoveCircle } from "react-icons/io";

import GetCookie from "../../../../hooks/getCookie";
import { getStatusClass, getRowClass } from "../../../../util/colors";
import { calculateCountdown } from "../../../../util/calculateCountdown";
import Status from "./Status";
import Attachments from "../../../Attachments";
import Notes from "../../../Notes";
import { emitTaskNotification } from "../../../../util/io";
import { format } from "date-fns";
import ExpandableDescription from "../../../ExpandableDescription";

//Comment validation
const commentReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE":
      return {
        ...state,
        value: action.comment,
        isvalid: validate(action.comment, action.validators),
      };
    case "TOUCH":
      return {
        ...state,
        isTouched: true,
      };
    default:
      return state;
  }
};

const TaskDetails = () => {
  const token = GetCookie("UserC");
  const userId = JSON.parse(localStorage.getItem("UserCData"));
  const role = JSON.parse(localStorage.getItem("user"))?.user_role;
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  let { id } = useParams();

  const [task, setTask] = useState();
  const [notes, setNotes] = useState([]);
  const [offer, setOffer] = useState("");
  const [comments, setComments] = useState([]);
  const [client, setClient] = useState([]);
  const [speciality, setSpeciality] = useState([]);
  const [user, setUser] = useState([]);
  const [status, setStatus] = useState([]);
  const [currency, setCurrency] = useState([]);

  const [statuses, setStatuses] = useState([]);
  const [reviews, setReviews] = useState([]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, "dd/MM/yyyy HH:mm");
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const promises = [
          axios.get(
            `${process.env.REACT_APP_BACKEND_URL}:5001/api/task/${id}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          ),
          axios.get(`${process.env.REACT_APP_BACKEND_URL}:5001/api/status/`, {
            headers: { Authorization: `Bearer ${token}` },
          }),
        ];
        const responses = await Promise.all(promises);
        const taskRes = responses[0];
        const statusesRes = responses[1];
        setTask(taskRes.data.task);
        setOffer(taskRes.data.offer);
        setClient(taskRes.data.task.client);
        setCurrency(taskRes.data.task.task_currency);
        setSpeciality(taskRes.data.task.speciality);
        setStatus(taskRes.data.task.taskStatus);
        setUser(taskRes.data.task.created_by);

        setReviews(taskRes.data.reviews);
        setNotes(taskRes.data.notes);
        setComments(taskRes.data.comments);

        setStatuses(statusesRes.data.statuses);
      } catch (error) {
        if (error.response) {
          console.log(error.response.data.err);
          setError({
            type: "error",
            message: error.response.data.err || "Error",
          });
        } else {
          console.log(error.message);
          setError({ type: "error", message: error.message || "Error" });
        }
      }
      setIsLoading(false);
    })();
  }, []);

  //////////////////////////////////////

  //delete task
  const deleteTaskHandler = async () => {
    setIsLoading(true);
    try {
      setError(null);
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}:5001/api/task/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseData = response;

      setError(responseData.data.message);
      setIsLoading(false);
      window.location.href = "/yourtasks";
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      if (error.response) {
        setError(
          error.response.data.err || "SomeThing Went Wrong , Please Try Again ."
        );
      } else if (error.request) {
        setError(error.request);
      } else {
        setError(error.message || "Error");
      }
    }
  };

  //comment validation
  const [commentState, dispatch5] = useReducer(commentReducer, {
    value: "",
    isvalid: false,
    isTouched: false,
  });

  const commentChangeHandler = (event) => {
    dispatch5({
      type: "CHANGE",
      comment: event.target.value,
      validators: [VALIDATOR_MINLENGTH(3)],
    });
  };
  const commentTouchHandler = () => {
    dispatch5({
      type: "TOUCH",
    });
  };

  //add Comment
  const addCommentHandler = async (event) => {
    event.preventDefault();
    // send api request to validate data
    setIsLoading(true);
    try {
      setError(null);
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}:5001/api/comment/`,
        {
          content: commentState.value,
          task_id: task?._id,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const responseData = await response;

      if (!(response.statusText === "OK")) {
        throw new Error(responseData.data.message);
      }

      emitTaskNotification(
        `Task No: ${task.serialNumber} - New Comment Added`,
        task
      );

      setError(responseData.data.message);
      setComments(responseData.data.comments);
      commentState.value = "";
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setError(err.message && "SomeThing Went Wrong , Please Try Again .");
    }
  };

  //delete Comment
  const deleteCommentHandler = async (commentId) => {
    setIsLoading(true);
    try {
      setError(null);
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}:5001/api/comment/`,
        {
          headers: { Authorization: `Bearer ${token}` },
          data: { commentID: commentId },
        }
      );
      const responseData = await response;
      setError(responseData.data.message);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setError(err.message || "SomeThing Went Wrong , Please Try Again .");
    }
  };

  const navigate = useNavigate();
  const [editTask, setEditTask] = useState(false);
  const [countdown, setCountdown] = useState({});

  useEffect(() => {
    if (task && task.deadline) {
      setCountdown(calculateCountdown(task.deadline));
    }
    const interval = setInterval(() => {
      if (task && task.deadline) {
        setCountdown(calculateCountdown(task.deadline));
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [task]);

  return (
    <>
      <ErrorModal error={error} onClear={() => setError(null)} />
      {isLoading ? (
        <LoadingSpinner asOverlay />
      ) : (
        <>
          {!task ? (
            <div className="flex justify-center items-center w-full p-3 min-h-[calc(100vh-65px)] space-y-2">
              Task Not Found
            </div>
          ) : (
            <div className="flex flex-col w-full p-3 min-h-[calc(100vh-65px)] space-y-2">
              <div className="relative flex flex-row justify-center w-full p-1 mb-4">
                <button
                  className="absolute top-0 left-0 p-2 text-3xl"
                  onClick={() => navigate("/yourtasks")}
                >
                  <TiArrowBack />
                </button>
                <h2 className=" text-2xl font-bold lg:text-3xl">
                  Task Details
                </h2>
              </div>

              <div className="w-full max-w-5xl 2xl:max-w-6xl mx-auto">
                <div className="flex items-center justify-between bg-white drop-shadow rounded px-8 py-3">
                  <div className="w-1/3 font-bold">{task.serialNumber}</div>
                  <div className={`w-1/3 flex justify-center`}>
                    <span
                      className={`rounded-md text-xs font-bold px-4 py-2 ${getRowClass(
                        task.taskStatus?.statusname
                      )} ${getStatusClass(task.taskStatus?.statusname)}`}
                    >
                      {task.taskStatus?.statusname === "approved" || task.taskStatus?.statusname === "pending"
                        ? "Waiting Offer"
                        : task.taskStatus?.statusname === "on going" &&
                          reviews.length > 0
                        ? "Returned"
                        : task.taskStatus?.slug === "edit" ? `${task.taskStatus?.statusname?.charAt(0).toUpperCase() + task.taskStatus?.statusname?.slice(1)} - ${task.lastEdit}` : task.taskStatus?.statusname?.charAt(0).toUpperCase() + task.taskStatus?.statusname?.slice(1) || "N/A"}
                    </span>
                  </div>
                  <div className="w-1/3"></div>
                </div>
              </div>

              <div className="flex w-full max-w-5xl 2xl:max-w-6xl mx-auto space-x-2">
                <div className="w-2/3 bg-white rounded px-8 py-3 drop-shadow">
                  <div className="grid grid-cols-3 gap-2">
                    <div className="">
                      <h5 className="m-0 p-0 font-semibold text-base text-gray-400">
                        Title
                      </h5>
                      <p className="font-medium border rounded my-0 mr-0 p-2 flex-1  drop-shadow-sm">
                        {task.title}
                      </p>
                    </div>
                    <div className="">
                      <h5 className="m-0 p-0 font-semibold text-base text-gray-400">
                        Language
                      </h5>
                      <p className="font-medium border rounded my-0 mr-0 p-2 flex-1  drop-shadow-sm">
                        {task.language ? task.language : ""}
                      </p>
                    </div>
                    <div className="">
                      <h5 className="m-0 p-0 font-semibold text-base text-gray-400">
                        Speciality
                      </h5>
                      <p className="font-medium border rounded my-0 mr-0 p-2 flex-1  drop-shadow-sm">
                        {speciality && speciality.sub_speciality}
                      </p>
                    </div>

                    {task.freelancer && (
                      <>
                        <div className="">
                          <h5 className="m-0 p-0 font-semibold text-base text-gray-400">
                            Freelancer
                          </h5>
                          <p className="font-medium border rounded my-0 mr-0 p-2 flex-1  drop-shadow-sm">
                            <Link
                              className="text-dark fw-bold"
                              to={`/freelancer/${task.freelancer?._id}`}
                            >
                              {task.freelancer.freelancername}
                            </Link>
                          </p>
                        </div>
                      </>
                    )}

                    {task.taskStatus?.statusname !== "waiting offer" &&
                      task.taskStatus?.statusname !== "approved" &&
                      task.taskStatus?.statusname !== "offer submitted" &&
                      task.taskStatus?.statusname !== "pending" &&
                      task.cost && (
                        <div className="">
                          <h5 className="m-0 p-0 font-semibold text-base text-gray-400">
                            Freelancer Price
                          </h5>
                          <p className="font-medium border rounded my-0 mr-0 p-2 flex-1  drop-shadow-sm">
                            {task.cost /
                              (task.freelancer?.currency.priceToEGP || 1)}
                          </p>
                        </div>
                      )}

                    <ExpandableDescription 
                      description={task.description}
                      maxLines={3} // Adjust this number as needed
                    />

                    <div className="col-span-3">
                      <h5 className="m-0 p-0 font-semibold text-base text-gray-400">
                        DeadLine
                      </h5>
                      <div className="flex space-x-2 items-center justify-between">
                        <p className="flex items-center justify-between font-medium border rounded my-0 mr-0 p-2 flex-1  drop-shadow-sm">
                          <div className="flex space-x-1">
                            <span className="text-danger">Date:</span>
                            <span>
                              {task.deadline && formatDate(task.deadline)}
                            </span>
                          </div>
                        </p>
                        <p className="space-x-1 font-medium border rounded my-0 mr-0 p-2 flex-1  drop-shadow-sm">
                          <span className="text-danger">Countdown:</span>
                          <span>{task.deadline && countdown.countdown}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-1/3 flex flex-col">
                  <Notes title={"Reviews"} data={reviews}  height="96"/>
                </div>
              </div>

              <Status
                status={task.taskStatus?.statusname}
                task={task}
                setTask={setTask}
                offer={offer}
                setOffer={setOffer}
              />

              <Attachments task={task} />

              <div className="w-full max-w-5xl 2xl:max-w-6xl mx-auto bg-white drop-shadow rounded p-2">
                <h1 className="text-2xl text-gray-600 font-semibold mb-3">
                  Comments
                </h1>
                <div className="space-y-1.5">
                  {!comments.length == 0 ? (
                    comments.map(
                      (comment) =>
                        (!comment.freelancer_id ||
                          comment.freelancer_id._id === userId) && (
                            <>
                              <div key={comment?._id} className="flex items-center space-x-4">
                                <div className="w-10 h-10 bg-blue-100 rounded-full flex justify-center items-center text-center capitalize">
                                  {((comment.user_id && comment.user_id.fullname) || (comment.freelancer_id && comment.freelancer_id.freelancername)).charAt(0)}
                                </div>
                                <div className="text-lg text-center">
                                  <p className="m-0 p-0 text-sm text-blue-500 text-left">{(comment.user_id && comment.user_id.fullname) || (comment.freelancer_id && comment.freelancer_id.freelancername)}</p>
                                  <p className="m-0 p-0 text-sm text-black text-left">{comment.content.split('\n').map((line, index) => (
                                    <React.Fragment key={index}>
                                      {line}
                                      {index < comment.content.split('\n').length - 1 && <br />}
                                    </React.Fragment>
                                  ))}</p>
                                  <p className="m-0 p-0 text-xs text-gray-300 text-left">
                                    {formatDate(comment.createdAt)}
                                  </p>
                                </div>
                              </div>
                              <hr className="my-2" />
                            </>
                        )
                    )
                  ) : (
                    <div className="">
                      <p className="">There Is No Comments </p>
                    </div>
                  )}
                </div>

                <div className="mt-8 flex items-center space-x-2 justify-between">
                  <textarea
                    type="text"
                    placeholder="Add Comment"
                    value={commentState.value}
                    onChange={commentChangeHandler}
                    onBlur={commentTouchHandler}
                    isvalid={commentState.isvalid.toString()}
                    className={`flex-1 p-2 ${
                      !commentState.isvalid &&
                      commentState.isTouched &&
                      "text-red-500"
                    }`}
                  />
                  <button
                    onClick={addCommentHandler}
                    disabled={!commentState.isvalid}
                    className="w-1/5 py-3.5 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                  >
                    Add Comment
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default TaskDetails;
