import React, { useState } from "react";
import { Formik, Form } from "formik";
import axios from "../../../../../axios";
import LoadingSpinner from "../../../../../LoadingSpinner/LoadingSpinner";
import { emitTaskNotification } from "../../../../../util/io";
import { format } from 'date-fns';
import { Link } from "react-router-dom";

const WaitingOffer = ({ taskId, offers, task }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState();
  const initialValues = {
    cost: 0,
    newDeadline: "",
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, 'dd/MM/yyyy HH:mm');
  };

  const onSubmit = async (values) => {
    try {
      setIsLoading(true);
      await axios.post(`/task/action/offer/${taskId}`, values);
      emitTaskNotification(
        `Task No: ${task.serialNumber} - Offer Submitted`,
        task
      );
      window.location.reload();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (error.response) {
        console.log(error.response.data.err);
      } else {
        console.log(error.message);
      }
    }
  };

  const notAvailableHandler = async () => {
    try {
      setIsLoading(true);
      await axios.post(`/task/action/availablity/${taskId}`, {});
      emitTaskNotification(
        `Task No: ${task.serialNumber} - Status Changed From Approved To Not Available`,
        task
      );
      window.location.reload();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (error.respose) {
        setMessage({ type: "error", message: error.response.data.err });
      } else {
        setMessage({ type: "error", message: error.message });
      }
    }
  }

  return isLoading ? (
    <LoadingSpinner />
  ) : offers.length === 0 ? (
    <>
      <div className="flex items-end justify-between">
        <p>No Offers found</p>
        <button
        onClick={notAvailableHandler}
        className="bg-red-500 text-white px-4 py-2 rounded"
        >
          Not Available
        </button>
      </div>
      <Formik initialValues={initialValues} onSubmit={onSubmit} className="">
        {({ getFieldProps, setFieldValue, values }) => (
          <Form className="flex items-end justify-between">
            <div className="w-1/3">
              <label htmlFor="cost" className="text-gray-400 font-semibold">
                Cost
              </label>
              <input type="number" {...getFieldProps("cost")} />
            </div>
            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded active:scale-95"
            >
              Submit
            </button>
          </Form>
        )}
      </Formik>
    </>
  ) : (
    <div className="space-y-2">
      <Formik initialValues={initialValues} onSubmit={onSubmit} className="">
        {({ getFieldProps, setFieldValue, values }) => (
          <>
            <Form className="flex items-end justify-between">
              <div className="w-1/3">
                <label htmlFor="cost" className="text-gray-400 font-semibold">
                  Cost
                </label>
                <input type="number" {...getFieldProps("cost")} />
              </div>
              <button
                type="submit"
                className="bg-blue-500 text-white px-4 py-2 rounded active:scale-95"
              >
                Submit
              </button>
            </Form>
            {offers && offers.length > 0 && (
              <table className="w-full border border-collapse text-center">
                <thead>
                  <tr>
                    <th className="border p-2">Freelancer</th>
                    <th className="border p-2">On Going</th>
                    <th className="border p-2">Completed</th>
                    <th className="border p-2">Offer</th>
                    <th className="border p-2">Deadline</th>
                    <th className="border p-2">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {offers.map((offer) => (
                    <tr key={offer?._id}>
                      <td className="border p-2">
                        <>
                          <Link
                            className="text-black no-underline hover:underline"
                            to={`/freeLancer/${offer.freelancer?._id}`}
                          >
                            {offer.freelancer.freelancername}
                          </Link>
                          <p>{formatDate(offer.createdAt)}</p>
                        </>
                      </td>
                      <td className="border p-2">{offer.onGoingTasks || 0}</td>
                      <td className="border p-2">{offer.deliveredTasks || 0}</td>
                      <td className="border p-2">{
                        offer.offer > 0 ? (
                          `${(offer.offer * offer.freelancer.currency.priceToEGP)} EGP`
                        ) : (
                          "Excused"
                        )}
                      </td>
                      <td className={offer.offer_deadline > task.deadline ? "border p-2 text-red-500" : "border p-2"}>
                        {offer.offer > 0 ? (
                          <>
                            <p>{!offer.offer_deadline ? formatDate(task.deadline) : formatDate(offer.offer_deadline)}</p>
                            <p>{offer.excuse}</p>
                          </>
                        ) : (
                          offer.excuse
                        )}
                      </td>
                      <td className="border p-2">
                        {/* select this offer button */}
                        {offer.offer > 0 ? (
                          <button
                            onClick={() => {
                              setFieldValue("cost", (offer.offer * offer.freelancer.currency.priceToEGP));
                              if(offer.offer_deadline) setFieldValue("newDeadline", offer.offer_deadline);
                            }}
                            disabled={values.cost === (offer.offer * offer.freelancer.currency.priceToEGP)}
                            className="bg-blue-500 text-white px-4 py-2 mx-auto rounded active:scale-95 disabled:cursor-not-allowed disabled:bg-gray-400 disabled:active:scale-100"
                          >
                            Select
                          </button>
                        ) : (
                          ""
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            <button
            onClick={notAvailableHandler}
            className="bg-red-500 text-white px-4 py-2 rounded float-right"
            >
              Not Available
            </button>
          </>
        )}
      </Formik>
    </div>
  );
};

export default WaitingOffer;
