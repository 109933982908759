import React, { useState } from "react";
import LoadingSpinner from "../../../../../LoadingSpinner/LoadingSpinner";
import { FiCheckCircle, FiClock, FiSettings, FiSlash, FiXCircle } from "react-icons/fi";
import { Link } from "react-router-dom";
import axios from "../../../../../axios";
import { IoCodeWorking } from "react-icons/io5";
import { emitTaskNotification } from "../../../../../util/io";

const Delivered = ({ taskId, edits = [], setTask, task, percentages }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [gain, setGain] = useState(0);

  const handleEdit = async (id, action) => {
    try {
      setIsLoading(true);
      if (action === "rejected") {
        await axios.post(`/task/action/edit/steps/${id}`, {
          taskId,
          status: action,
        });
        emitTaskNotification(
          `Task No: ${task.serialNumber} - Edit has been rejected`,
          task
        );
      } else if (action === "accepted") {
        if (!gain) {
          alert("Add Price First");
          return;
        }
        await axios.post(`/task/action/edit/steps/${id}`, {
          taskId,
          status: action,
          gain,
        });
        emitTaskNotification(
          `Task No: ${task.serialNumber} - Edit has been accepted`,
          task
        );
      } else if (action === "delivered") {
        await axios.post(`/task/action/edit/steps/${id}`, {
          taskId,
          status: action,
        });
        emitTaskNotification(
          `Task No: ${task.serialNumber} - Edit has been delivered`,
          task
        );
      }
      window.location.reload();
    } catch (error) {
      if (error.response) {
        console.log(error.response.data.err);
      } else {
        console.log(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return isLoading ? (
    <div className="w-full flex items-center justify-center">
      <LoadingSpinner />
    </div>
  ) : (
    <div className="">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl text-gray-600 font-semibold">Edits</h1>
        <Link
          to={`/task/${taskId}/add-edit`}
          className="bg-blue-500 text-white px-3 py-1 rounded-md no-underline"
        >
          Add Edit
        </Link>
      </div>
      {edits && edits.length > 0 ? (
        <table className="table-auto border border-collapse w-full text-center">
          <thead>
            <tr>
              <th className="border px-4 py-2 w-3/12">Description</th>
              <th className="border px-4 py-2">Deadline</th>
              <th className="border px-4 py-2">Note</th>
              <th className="border px-4 py-2">Offer</th>
              <th className="border px-4 py-2">Paid</th>
              <th className="border px-4 py-2 w-1/12">Actions</th>
            </tr>
          </thead>
          <tbody>
            {edits.map((edit, index) => (
              <tr key={index}>
                <td className="border px-4 py-2">{edit.description}</td>
                <td className="border px-4 py-2 bg-green-100">
                  {edit.deadline?.split("T")[0]}{" "}
                  {edit.deadline?.split("T")[1]?.split(".")[0]}
                </td>
                <td className="border px-4 py-2 bg-green-100">{edit.note}</td>
                <td className="border px-4 py-2 bg-green-100">{`${
                  edit.cost / percentages.currencyValue +
                  edit.cost * percentages.specialistProfitMinPercentage
                } - ${
                  edit.cost / percentages.currencyValue +
                  edit.cost * percentages.specialistProfitMaxPercentage
                }`}</td>
                <td className="border px-4 py-2">
                  {edit.status === "not included" ? (
                    <>
                      <input
                        type="number"
                        value={gain}
                        onChange={(e) => setGain(e.target.value)}
                        className="border px-2 py-1"
                      />
                    </>
                  ) : (
                    edit.gain
                  )}
                </td>
                <td className="border px-4 py-2">
                  {edit.status === "delivered" ? (
                    <FiCheckCircle className="text-green-500 w-6 h-6 mx-auto" />
                  ) : edit.status === "done" ? (
                    <button
                      className="bg-green-500 text-white px-3 py-1 rounded-md"
                      onClick={() => handleEdit(edit?._id, "delivered")}
                    >
                      Deliver
                    </button>
                  ) : edit.status === "not included" ? (
                    <>
                      <button
                        className="bg-green-500 text-white px-3 py-1 rounded-md"
                        onClick={() => handleEdit(edit?._id, "accepted")}
                      >
                        Accept
                      </button>
                      <button
                        className="bg-red-500 text-white px-3 py-1 my-2 rounded-md"
                        onClick={() => handleEdit(edit?._id, "rejected")}
                      >
                        Reject
                      </button>
                    </>
                  ) : edit.status === "included" ||
                    edit.status === "accepted" ? (
                    <FiSettings className="text-blue-500 w-6 h-6 mx-auto" />
                  ) : edit.status === "pending" ? (
                    <FiClock className="text-yellow-500 w-6 h-6 mx-auto" />
                  ) : edit.status === "rejected" ? (
                    <FiXCircle className="text-red-500 w-6 h-6 mx-auto" />
                  ) : (
                    <FiSlash className="text-green-500 w-6 h-6 mx-auto" />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p className="text-center">No edits made</p>
      )}
    </div>
  );
};

export default Delivered;
