import React, { useState, useRef, useEffect } from 'react';

const ExpandableDescription = ({ description, maxLines = 3 }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const textRef = useRef(null);

  useEffect(() => {
    const checkHeight = () => {
      const element = textRef.current;
      if (element) {
        const lineHeight = parseInt(window.getComputedStyle(element).lineHeight);
        const maxHeight = lineHeight * maxLines;
        const actualHeight = element.scrollHeight;
        setShowButton(actualHeight > maxHeight);
      }
    };

    checkHeight();
    window.addEventListener('resize', checkHeight);
    return () => window.removeEventListener('resize', checkHeight);
  }, [description, maxLines]);

  const style = !isExpanded ? {
    display: '-webkit-box',
    WebkitLineClamp: maxLines,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden'
  } : {};

  return (
    <div className="col-span-3">
      <h5 className="m-0 p-0 font-semibold text-base text-gray-400">
        Description
      </h5>
      <div className="relative">
        <p
          ref={textRef}
          style={style}
          className="font-medium border rounded my-0 mr-0 p-2 flex-1 drop-shadow-sm whitespace-pre-wrap text-right rtl"
        >
          {description.split('\n').map((line, index) => (
            <React.Fragment key={index}>
              {line}
              {index < description.split('\n').length - 1 && <br />}
            </React.Fragment>
          ))}
        </p>
        {showButton && (
          <button
            onClick={() => setIsExpanded(!isExpanded)}
            className="mt-2 text-blue-600 hover:text-blue-800 font-medium text-sm"
          >
            {isExpanded ? 'See less' : 'See more'}
          </button>
        )}
      </div>
    </div>
  );
};

export default ExpandableDescription;