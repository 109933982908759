import React, { useEffect, useState } from "react";
import LoadingSpinner from "../../../../LoadingSpinner/LoadingSpinner";
import ErrorModal from "../../../../LoadingSpinner/ErrorModal";

import { useParams, useNavigate, Link } from "react-router-dom";
import { TiArrowBack } from "react-icons/ti";

import { AiOutlineTransaction } from "react-icons/ai";
import TransactionsTable from "../../../Wallet/TransactionsTable";
import Pagination from "../../../Pagination";
import instance from "../../../../axios";
import DateFilter from "../../../DateFilter";

const ExportCSVButton = ({ jsonData }) => {
  const handleExport = () => {
    const header = Object.keys(jsonData[0]).join(",");
    const csvData = [
      header,
      ...jsonData.map((row) => Object.values(row).join(",")),
    ].join("\n");
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "data.csv";
    a.click();
    URL.revokeObjectURL(url);
  };

  return (
    <button
      onClick={handleExport}
      type="button"
      className="py-1 px-4 border-2 border-blue-600 text-blue-600 rounded transition-all hover:bg-blue-600 hover:text-white active:scale-95"
    >
      Export
    </button>
  );
};

const AccountDetails = () => {
  let { id } = useParams();

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(30);
  const [count, setCount] = useState();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [account, setAccount] = useState([]);
  const [clients, setClients] = useState([]);
  const [freeLancers, setFreeLancers] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [selectedTransactions, setSelectedTransactions] = useState([]);
  const [start, setStart] = useState();
  const [end, setEnd] = useState();
  const [selectedRange, setSelectedRange] = useState({ start, end });
  const [buttonLoading, setButtonLoading] = useState(false);

  const getJsonData = () => {
    const jsonData = selectedTransactions.map((transaction) => {
      return {
        Title: transaction.task?.title,
        "Serial Number": transaction.task?.serialNumber,
        Amount: transaction.amount,
        Date: new Date(transaction.createdAt).toDateString(),
        Paid: transaction.finished,
      };
    });
    return jsonData;
  };

  useEffect(() => {
    // /transaction/id
    (async () => {
      try {
        const params = { 
          page, 
          limit, 
          account_id: id, 
          start: selectedRange.start,
          end: selectedRange.end,
        };
        setIsLoading(true);
        const transactionsRes = await instance.get(`/transaction`, {
          params,
        });
        const accountRes = await instance.get(`/account/${id}`);
        const clientRes = await instance.get(`/client/`);
        const freelancerRes = await instance.get(`/freelancer/`);

        setTransactions(transactionsRes.data.transactions);
        setCount(transactionsRes.data.count);
        setAccount(accountRes.data.account);
        setClients(clientRes.data.clients);
        setFreeLancers(freelancerRes.data.freelancers);
      } catch (error) {
        if (error.response) {
          setError({ type: "error", message: error.response.data.err });
        } else {
          setError({ type: "error", message: error.message });
        }
      } finally {
        setIsLoading(false);
      }
    })();
  }, [page, limit, selectedRange]);

  const [datePickerOpen] = useState(false);
  const onChange = (dates) => {
    const [start, end] = dates;
    setStart(start);
    setEnd(end);
  };

  useEffect(() => {
    if (start && end) {
      setSelectedRange({ start, end });
    }
  }, [start, end]);
  const confirmPaymentHandler = async () => {
    try {
      setButtonLoading(true);
      const data = selectedTransactions.map((t) => t?._id);
      const response = await instance.post(`/transaction/action/finish`, {
        transactions: data,
      });
      setError({ type: "success", message: response.data.message });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      if (error.response) {
        setError({ type: "error", message: error.response.data.err });
      } else {
        setError({ type: "error", message: error.message });
      }
    } finally {
      setButtonLoading(false);
    }
  };

  //error message
  const errorHandler = () => {
    setError(null);
  };

  return isLoading ? (
    <LoadingSpinner asOverlay />
  ) : (
    <div className="flex flex-col items-center w-full p-3 min-h-[calc(100vh-65px)]">
      <ErrorModal message={error} onClear={errorHandler} />

      <div className="w-full flex items-center justify-center relative">
        <div className="absolute left-0">
          <button className="text-2xl" onClick={() => navigate("/accounts")}>
            <TiArrowBack />
          </button>
        </div>
        <h2 className="text-center p-2 pt-3 m-0 font-bold">Account Details</h2>
      </div>

      <div className="bg-white p-3 m-1 w-full grid grid-cols-2 gap-2 drop-shadow rounded">
        <div className="flex items-center mx-auto">
          <h4 className="m-0 p-0 font-normal">UserName :</h4>
          <p className="font-bold ml-2 mb-0">{account.title}</p>
        </div>
        <div className="flex items-center mx-auto">
          <h4 className="m-0 p-0 font-normal"> Owner:</h4>
          <p className="font-bold ml-2 mb-0">
            <Link to={`/${account.type}/${account.owner}`}>Owner Details</Link>
          </p>
        </div>
        <div className="flex items-center mx-auto">
          <h4 className="m-0 p-0 font-normal"> AccountType:</h4>
          <p className="font-bold ml-2 mb-0">{account.type}</p>
        </div>
        <div className="flex items-center mx-auto">
          <h4 className="m-0 p-0 font-normal"> Balance:</h4>
          <h4 className="font-bold ml-2 mb-0">{Math.floor(account.balance)}</h4>
        </div>
        <div className="flex items-center mx-auto">
          <h4 className="m-0 p-0 font-normal"> Payment Method</h4>
          <p className="font-bold ml-2 mb-0">{account.method || "N/A"}</p>
        </div>
        <div className="flex items-center mx-auto">
          <h4 className="m-0 p-0 font-normal"> Account Number:</h4>
          <p className="font-bold ml-2 mb-0">
            {account.accountNumber || "N/A"}
          </p>
        </div>
      </div>

      <h1 className="p-2 fw-bold">Transactions</h1>

      <div className="ml-auto my-4">
          <DateFilter
            startDate={start}
            endDate={end}
            onChange={onChange}
            datePickerOpen={datePickerOpen}
          />
        </div>

      <div className="w-full flex items-center justify-between">
        <div className="w-1/2 flex items-center space-x-2">
          <ExportCSVButton jsonData={getJsonData()} />
          <button
            onClick={confirmPaymentHandler}
            disabled={buttonLoading}
            type="button"
            className="disabled:opacity-50 disabled:cursor-not-allowed py-1 px-4 border-2 border-blue-600 text-blue-600 rounded transition-all hover:bg-blue-600 hover:text-white active:scale-95"
          >
            Confirm Payment
          </button>
        </div>

        <button
          className="group p-2 mb-2 font-bold flex items-center rounded-md bg-gray-300 transition-all hover:bg-blue-500 hover:text-white"
          onClick={() => navigate(`/transactions?id=${account?._id}`)}
        >
          <AiOutlineTransaction className="text-2xl mr-1 transition-all group-hover:text-white group-hover:rotate-180" />
          Add New Transaction
        </button>
      </div>

      {transactions && !transactions.length == 0 ? (
        <>
          <TransactionsTable
            account={account}
            freeLancers={freeLancers}
            transactions={transactions}
            clients={clients}
            selectedTransactions={selectedTransactions}
            setSelectedTransactions={setSelectedTransactions}
          />
          <Pagination
            currentPage={page}
            totalPages={Math.ceil(count / limit)}
            onNextPage={() => setPage(page + 1)}
            onPreviousPage={() => setPage(page - 1)}
            onFirstPage={() => setPage(1)}
            onLastPage={() => setPage(Math.ceil(count / limit))}
          />
        </>
      ) : (
        <div className="p-3 m-1 justify-center">
          <h4>There Is No Transactions Right Now</h4>
        </div>
      )}
    </div>
  );
};

export default AccountDetails;
