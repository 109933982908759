import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import instance from "../../axios";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import ErrorModal from "../../LoadingSpinner/ErrorModal";
import TransactionsTable from "./TransactionsTable";
import Pagination from "../Pagination";

const Wallet = ({}) => {
  const id = JSON.parse(localStorage.getItem("user"))?._id;

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(30);
  const [count, setCount] = useState();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [account, setAccount] = useState([]);
  const [clients, setClients] = useState([]);
  const [freeLancers, setFreeLancers] = useState([]);
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const params = { page, limit, account_id: id };
      setIsLoading(true);
      try {
        const [transactionRes, accountRes, clientsRes, freelancersRes] =
          await Promise.all([
            await instance.get(`/transaction`, {
              params,
            }),
            instance.get(`account/${id}`),
            instance.get(`client/`),
            instance.get(`freelancer/`),
          ]);
        setAccount(accountRes.data.account);
        setTransactions(transactionRes.data.transactions);
        setCount(transactionRes.data.count);
        setClients(clientsRes.data.clients);
        setFreeLancers(freelancersRes.data.freelancers);
      } catch (error) {
        if (error.response) {
          setError({ type: "error", message: error.response.data.err });
        } else {
          setError({ type: "error", message: error.message });
        }
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [page, limit]);

  return isLoading ? (
    <LoadingSpinner asOverlay />
  ) : (
    <>
      <ErrorModal message={error} onClear={() => setError(null)} />
      <div className="flex flex-col items-center p-4 min-h-[calc(100vh-65px)]">
        {/* <div className="w-full flex items-center justify-center relative">
          <div className="absolute left-0">
            <button className="text-2xl" onClick={() => navigate("/accounts")}>
              <TiArrowBack />
            </button>
          </div>
          <h2 className="text-center p-2 pt-3 m-0 font-bold">
            Account Details
          </h2>
        </div> */}

        <div className="bg-white p-3 m-1 w-full grid grid-cols-2 gap-2 drop-shadow rounded">
          <div className="flex items-center mx-auto">
            <h4 className="m-0 p-0 font-normal">Name:</h4>
            <p className="font-bold ml-2 mb-0">{account.title}</p>
          </div>
          <div className="flex items-center mx-auto">
            <h4 className="m-0 p-0 font-normal"> Balance:</h4>
            <h4 className="font-bold ml-2 mb-0">{Math.floor(account.balance)}</h4>
          </div>
        </div>

        <h1 className="p-2 fw-bold">Transactions</h1>

        {transactions && !transactions.length == 0 ? (
          <>
            <TransactionsTable
              account={account}
              freeLancers={freeLancers}
              transactions={transactions}
              clients={clients}
            />
            <Pagination
              currentPage={page}
              totalPages={Math.ceil(count / limit)}
              onNextPage={() => setPage(page + 1)}
              onPreviousPage={() => setPage(page - 1)}
              onFirstPage={() => setPage(1)}
              onLastPage={() => setPage(Math.ceil(count / limit))}
            />
          </>
        ) : (
          <div className="p-3 text-gray-500">
            <h4>There Is No Transactions Right Now</h4>
          </div>
        )}
      </div>
    </>
  );
};

export default Wallet;
