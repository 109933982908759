import React, { useEffect, useState } from "react";
import axios from "../../../../../axios";
import LoadingSpinner from "../../../../../LoadingSpinner/LoadingSpinner";
import { emitTaskNotification } from "../../../../../util/io";

const OfferSubmitted = ({
  cost,
  taskId,
  setStatus,
  currency,
  offer,
  setTask,
  task,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [paid, setPaid] = useState(0);
  const handleAccept = async () => {
    // if (
    //   paid <= 0 ||
    //   paid < offer.specialistOfferMin ||
    //   paid > offer.specialistOfferMax
    // )
    //   return alert("Invalid price");

    try {
      setIsLoading(true);
      if (!paid) {
        alert("Add Price First");
        return;
      }
      await axios.post(`/task/action/accept/${taskId}`, { paid });
      emitTaskNotification(
        `Offer on Task No: ${task.serialNumber} has been approved.`,
        task
      );
      setIsLoading(false);
      window.location.reload();
    } catch (error) {
      setIsLoading(false);
      if (error.response) {
        console.log(error.response.data.err);
      } else {
        console.log(error.message);
      }
    }
  };

  const handleReject = async () => {
    try {
      setIsLoading(true);
      await axios.post(`/task/action/refuse/${taskId}`);
      emitTaskNotification(
        `Offer on Task No: ${task.serialNumber} has been rejected.`,
        task
      );
      setStatus("rejected");
      setTask((prev) => ({
        ...prev,
        taskStatus: {
          ...prev.taskStatus,
          statusname: "rejected",
          slug: "rejected",
        },
      }));
      setIsLoading(false);
      window.location.reload();
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      if (error.response) {
        console.log(error.response.data.err);
      } else {
        console.log(error.message);
      }
    }
  };

  const handlePending = async () => {
    try {
      setIsLoading(true);
      if (paid === "" || paid <= 0) {
        alert("Add Price First");
        setIsLoading(false);
        return;
      }
      await axios.post(`/task/action/pend/${taskId}`);
      emitTaskNotification(
        `Offer on Task No: ${task.serialNumber} has been pended.`,
        task
      );
      setStatus("pending");
      setTask((prev) => ({
        ...prev,
        taskStatus: {
          ...prev.taskStatus,
          statusname: "pending",
          slug: "pending",
        },
      }));
      setIsLoading(false);

      window.location.reload();
    } catch (error) {
      setIsLoading(false);
      if (error.response) {
        console.log(error.response.data.err);
      } else {
        console.log(error.message);
      }
    }
  };

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <div className="">
      <div className="flex items-end justify-between">
        <div className="w-1/3">
          <span className="text-gray-400 font-semibold">Freelancer Offer</span>
          <p className="p-0 m-0">
            {`(${offer.specialistOfferMin?.toFixed(
              2
            )} - ${offer.specialistOfferMax?.toFixed(2)}) ${currency}`}
          </p>
        </div>
        <div className="w-1/3">
          <label htmlFor="paid" className="text-gray-400 font-semibold">
            Price
          </label>
          <input
            type="number"
            id="paid"
            className="w-full"
            value={paid}
            onChange={(e) => setPaid(e.target.value)}
          />
        </div>
        <div className="flex items-center space-x-2">
          <button
            onClick={handleAccept}
            className="bg-blue-500 text-white px-4 py-2 rounded active:scale-95"
          >
            Accept
          </button>
          <button
            onClick={handlePending}
            className="bg-yellow-500 text-white px-4 py-2 rounded active:scale-95"
          >
            Pending
          </button>
          <button
            onClick={handleReject}
            className="bg-red-500 text-white px-4 py-2 rounded active:scale-95"
          >
            Reject
          </button>
        </div>
      </div>
    </div>
  );
};

export default OfferSubmitted;
