import React, { useEffect, useState } from "react";
import Tasks from "./Tasks";
import Totals from "./Totals";
import Country from "./Country";
import TopUsers from "./Top.js";
import FreeFreelancers from "./Free.js";
import Breakdown from "./Breakdown.js";
import Clients from "./Clients.js";
import Users from "./Users.js";
import DateFilter from "../../../DateFilter.js";
import instance from "../../../../axios";
import MessageModal from "../../../../LoadingSpinner/ErrorModal.js";
import LoadingSpinner from "../../../../LoadingSpinner/LoadingSpinner.js";

const Home = ({}) => {
  const sectionClasses = "bg-white rounded drop-shadow";
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState();
  const [message, setMessage] = useState(null);
  const [datePickerOpen] = useState(false);
  const [start, setStart] = useState();
  const [end, setEnd] = useState();
  const [selectedRange, setSelectedRange] = useState({ start, end });
  const onChange = (dates) => {
    const [start, end] = dates;
    setStart(start);
    setEnd(end);
  };

  useEffect(() => {
    if (start && end) {
      setSelectedRange({ start, end });
    }
  }, [start, end]);

  useEffect(() => {
    (async () => {
      try {
        const params = { start: selectedRange.start, end: selectedRange.end };
        setIsLoading(true);
        const { data } = await instance.get("/", { params });
        console.log(data);
        setData(data);
      } catch (error) {
        if (error.response) {
          setMessage({ type: "error", message: error.response.data.err });
        } else {
          setMessage({ type: "error", message: error.message });
        }
      } finally {
        setIsLoading(false);
      }
    })();
  }, [selectedRange]);

  return isLoading ? (
    <LoadingSpinner asOverlay />
  ) : data ? (
    <div className="flex flex-col space-y-4">
      <MessageModal message={message} onClear={() => setMessage(null)} />
      <div className="flex items-center justify-between">
        <h1 className="text-4xl font-medium mt-4">Overview</h1>
        <div className="mt-2.5">
          <DateFilter
            datePickerOpen={datePickerOpen}
            startDate={start}
            endDate={end}
            onChange={onChange}
          />
        </div>
      </div>
      <div className="flex space-x-4 justify-between">
        <Tasks
          sectionClasses={sectionClasses}
          data={{
            tasksCount: data.tasksCount,
            completedCount: data.deliveredTasksCount,
            taskCompletion: data.deliveredTasksPercentage,
          }}
        />
        <Totals
          sectionClasses={sectionClasses}
          data={{
            totalGain: data.totalGain,
            totalCost: data.totalCost,
            totalProfit: data.totalProfit,
            totalProfitPercentage: data.totalProfitPercentage,
          }}
        />
      </div>
      <div className="flex space-x-4 justify-between">
        <TopUsers
          role="clients"
          data={data.topClients}
          sectionClasses={sectionClasses}
        />
        <TopUsers
          role="freelancers"
          data={data.topFreelancers}
          sectionClasses={sectionClasses}
        />
      </div>
      <div className="flex space-x-4 justify-between">
        <Breakdown source={"specilaities"} data={data.specialities} sectionClasses={sectionClasses} />
        <Breakdown source={"statuses"} data={data.statuses} sectionClasses={sectionClasses} />
      </div>
      <div className="flex space-x-4 justify-between">
        <FreeFreelancers sectionClasses={sectionClasses} />
      </div>
    </div>
  ) : (
    <div>No Data</div>
  );
};

export default Home;
