export function getRowClass(statusname) {
  switch (statusname) {
    case "pending":
      return "bg-orange-500";
    case "waiting offer":
      return "bg-yellow-100";
    case "approved":
      return "bg-blue-400";
    case "assigned":
      return "bg-purple-100";
    case "done":
      return "bg-green-100";
    case "delivered":
      return "bg-green-700";
    case "rejected":
      return "bg-red-100";
    case "not available":
      return "bg-gray-700";
    case "on going":
      return "bg-blue-700";
    case "offer submitted":
      return "bg-blue-100";
    case "edit":
      return "bg-purple-600";
    case "cancel":
      return "bg-red-700";
    case "in review":
      return "bg-yellow-500";
    default:
      return "";
  }
}

export function getStatusClass(statusname) {
  switch (statusname) {
    case "pending":
      return "text-orange-100";
    case "waiting offer":
      return "text-yellow-500";
    case "approved":
      return "text-blue-100";
    case "assigned":
      return "text-purple-500";
    case "done":
      return "text-green-700";
    case "delivered":
      return "text-green-100";
    case "rejected":
      return "text-red-500";
    case "not available":
      return "text-gray-100";
    case "on going":
      return "text-blue-100";
    case "offer submitted":
      return "text-blue-500";
    case "edit":
      return "text-purple-100";
    case "cancel":
      return "text-red-100";
    case "in review":
      return "text-yellow-100";
    default:
      return "";
  }
}
