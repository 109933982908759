import React, { useEffect, useState } from "react";
import axios from "../../../../../axios";
import LoadingSpinner from "../../../../../LoadingSpinner/LoadingSpinner";
import { emitTaskNotification } from "../../../../../util/io";
import ErrorModal from "../../../../../LoadingSpinner/ErrorModal";
import { format } from 'date-fns';
import { Link } from "react-router-dom";

const Approved = ({ taskId, setStatus, cost = false, offers, taskCost, task }) => {
  const [freelancers, setFreeLancers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState();

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, 'dd/MM/yyyy HH:mm');
  };

  useEffect(() => {
    (() => {
      try {
        setIsLoading(true);
        if (taskCost > 0) {
          const filteredOffers = offers.filter((offer) => offer.offer <= taskCost);
          setFreeLancers(filteredOffers.map((offer) => offer.freelancer));
        } else {
          setFreeLancers(offers.map((offer) => offer.freelancer));
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        if (error.respose) {
          setMessage({ type: "error", message: error.response.data.err });
        } else {
          setMessage({ type: "error", message: error.message });
        }
      }
    })();
  }, []);

  const submitHandler = async (freelancer, price) => {
    if (!freelancer || freelancer === "") return alert("Invalid Freelancer");
    if (!cost && (!price || price < 0)) return alert("Invalid cost");
    try {
      setIsLoading(true);
      await axios.post(`/task/action/assign/${taskId}`, {
        freelancer: freelancer,
        cost: task.cost ? task.cost : price,
      });
      emitTaskNotification(
        `Task No: ${task.serialNumber} - Status Changed From Approved To Assigned`,
        task
      );
      setStatus("assigned");
      window.location.reload();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (error.respose) {
        setMessage({ type: "error", message: error.response.data.err });
      } else {
        setMessage({ type: "error", message: error.message });
      }
    }
  };

  const notAvailableHandler = async () => {
    try {
      setIsLoading(true);
      await axios.post(`/task/action/availablity/${taskId}`, {});
      emitTaskNotification(
        `Task No: ${task.serialNumber} - Status Changed From Approved To Not Available`,
        task
      );
      window.location.reload();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (error.respose) {
        setMessage({ type: "error", message: error.response.data.err });
      } else {
        setMessage({ type: "error", message: error.message });
      }
    }
  }
  
  return isLoading ? (
    <LoadingSpinner />
  ) : freelancers.length === 0 ? (
    <div className="flex items-end justify-between">
      <p>No freelancers found</p>
      <button
      onClick={notAvailableHandler}
      className="bg-red-500 text-white px-4 py-2 rounded"
      >
        Not Available
      </button>
    </div>
  ) : (
    <div className="space-y-2">
      <ErrorModal message={message} onClear={() => setMessage(null)} />
      {offers && offers.length > 0 && (
        <table className="w-full border border-collapse text-center">
          <thead>
            <tr>
              <th className="border p-2">Freelancer</th>
              <th className="border p-2">On Going</th>
              <th className="border p-2">Completed</th>
              <th className="border p-2">Offer</th>
              <th className="border p-2">Deadline</th>
              <th className="border p-2">Action</th>
            </tr>
          </thead>
          <tbody>
            {offers.map((offer) => (
              <tr key={offer?._id}>
                <td className="border p-2">
                  <>
                    <Link
                      className="text-black no-underline hover:underline"
                      to={`/freeLancer/${offer.freelancer?._id}`}
                    >
                      {offer.freelancer.freelancername}
                    </Link>
                    <p>{formatDate(offer.createdAt)}</p>
                  </>
                </td>
                <td className="border p-2">{offer.onGoingTasks || 0}</td>
                <td className="border p-2">{offer.deliveredTasks || 0}</td>
                <td className="border p-2">{
                  offer.offer > 0 ? (
                    `${(offer.offer * offer.freelancer.currency.priceToEGP)} EGP`
                  ) : (
                    "Excused"
                  )}
                </td>
                <td className={offer.offer_deadline > task.deadline ? "border p-2 text-red-500" : "border p-2"}>
                  {offer.offer > 0 ? (
                    <>
                      <p>{!offer.offer_deadline ? formatDate(task.deadline) : formatDate(offer.offer_deadline)}</p>
                      <p>{offer.excuse}</p>
                    </>
                  ) : (
                    offer.excuse
                  )}
                </td>
                <td className="border p-2">
                  {/* select this offer button */}
                  {offer.offer > 0 ? (
                    <button
                      onClick={() => {
                        submitHandler(offer.freelancer?._id, offer.offer * offer.freelancer.currency.priceToEGP);
                      }}
                      className="bg-blue-500 text-white px-4 py-2 mx-auto rounded active:scale-95 disabled:cursor-not-allowed disabled:bg-gray-400 disabled:active:scale-100"
                    >
                      Select
                    </button>
                  ) : (
                    ""
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default Approved;
