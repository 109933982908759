import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import socket from "./util/io";

import { AuthProvider, AuthContext } from "./AuthContext";

import Signin from "./components/Signin/Signin";
import Admin from "./components/Admin/Admin";
import UserA from "./components/UserA/UserA";
import UserB from "./components/UserB/UserB";
import UserC from "./components/UserC/UserC";
import UserD from "./components/UserD/UserD";
import NotificationCard from "./components/Notification";

import "react-datepicker/dist/react-datepicker.css";
function App() {
  const authContext = useContext(AuthContext);
  const [notification, setNotification] = useState();

  useEffect(() => {
    socket.on("notification", (data) => {
      // console.log("Received notification", data);
      setNotification({
        body: data.body,
        createdAt: data.createdAt,
        link: data.link,
      });
    });
  }, []);

  return (
    <div>
      <AnimatePresence>
        {notification && (
          <motion.div
            className="z-50 max-w-md w-full fixed top-20 right-4"
            initial={{ opacity: 0, x: 500 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 500 }}
            transition={{ duration: 0.35, type: "spring" }}
          >
            <NotificationCard
              realtime
              notification={notification}
              setNotification={setNotification}
            />
          </motion.div>
        )}
      </AnimatePresence>
      <BrowserRouter>
        {authContext.auth.admin ? (
          <Routes>
            <Route path="/*" element={<Admin />} />
          </Routes>
        ) : authContext.auth.usera ? (
          <Routes>
            <Route path="/*" element={<UserA />} />
          </Routes>
        ) : authContext.auth.userb ? (
          <Routes>
            <Route path="/*" element={<UserB />} />
          </Routes>
        ) : authContext.auth.userc ? (
          <Routes>
            <Route path="/*" element={<UserC />} />
          </Routes>
        ) : authContext.auth.userd ? (
          <Routes>
            <Route path="/*" element={<UserD />} />
          </Routes>
        ) : (
          <Routes>
            <Route path="/*" element={<Signin />} />
          </Routes>
        )}
      </BrowserRouter>
    </div>
  );
}

function AppWithStore() {
  return (
    <AuthProvider>
      <App />
    </AuthProvider>
  );
}

export default AppWithStore;
