import React, { useEffect, useState } from "react";
import ReactSelect from "react-select";
import axios from "../../../../../axios";
import LoadingSpinner from "../../../../../LoadingSpinner/LoadingSpinner";
import { emitTaskNotification } from "../../../../../util/io";

const Ongoing = ({ taskId, freelancer, setStatus, setTask, offers, task }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [freelancers, setFreeLancers] = useState([]);
  const [selectedFreelancer, setSelectedFreelancer] = useState(null);
  const [costValue, setCostValue] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        setFreeLancers(offers.map((offer) => offer.freelancer));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        if (error.respose) {
          console.log(error.response.data);
        } else {
          console.log(error);
        }
      }
    })();
  }, []);

  const handleDone = async () => {
    if (!selectedFreelancer) return;
    if (!costValue || costValue <= 0) return alert("Invalid cost");
    try {
      setIsLoading(true);
      await axios.post(`/task/action/assign/${taskId}`, {
        freelancer: selectedFreelancer,
        cost: costValue,
      });
      setStatus("assigned");
      emitTaskNotification(
        `Task No: ${task.serialNumber} - Status Changed From Ongoing To Assigned`,
        task
      );
      window.location.reload();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (error.response) {
        console.log(error.response.data);
      } else {
        console.log(error);
      }
    }
  };

  const handleRepublish = async () => {
    try {
      setIsLoading(true);
      await axios.post(`/task/action/republish/${taskId}`);
      emitTaskNotification(
        `Task No: ${task.serialNumber} - Has Been Republished`,
        task
      );
      window.location.reload();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (error.response) {
        console.log(error.response.data);
      } else {
        console.log(error);
      }
    }
  };


  return isLoading ? (
    <div className="flex items-center justify-between">
      <LoadingSpinner />
    </div>
  ) : (
    <div className="space-y-2">
      <div className="flex items-end justify-between">
        <div className="w-1/3">
          <label htmlFor="cost" className="text-gray-400 font-semibold">
            Change Freelancer
          </label>
          <div className="flex items-center space-x-2">
            <ReactSelect
              className="min-w-[250px]"
              onChange={(e) => {
                setSelectedFreelancer(e.value);
                setCostValue(offers.filter((offer) => offer.freelancer?._id === e.value)[0].offer);
              }}
              options={freelancers.map((freelancer) => ({
                label: freelancer.freelancername,
                value: freelancer?._id,
              }))}
            />
            <button
              onClick={handleDone}
              className="bg-blue-500 text-white px-4 py-2 rounded active:scale-95 ml-4"
            >
              Submit
            </button>
          </div>
        </div>
        <div className="w-1/3 flex justify-end space-x-2">
          <button
            onClick={handleRepublish}
            className="bg-yellow-500 text-white px-4 py-2 rounded active:scale-95"
          >
            Republish
          </button>
        </div>
      </div>
    </div>
  );
};

export default Ongoing;
