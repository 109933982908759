import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";

import UserCSideBar from "./UserCSideBar/UserCSideBar";
import Header from "../Header";
import Settings from "./Pages/Settings/Settings";
import ChangePass from "./Pages/Settings/ChangePass";
import Tasks from "./Pages/Tasks/Tasks";
import TaskDetails from "./Pages/Tasks/TaskDetails";
import Wallet from "../Wallet";

const UserC = () => {
  const [statusCount, setStatusCount] = useState({
    waitingOffer: 0,
    onGoing: 0,
    edit: 0,
    my: 0,
  });

  return (
    // <div className="">UserC</div>
    <>
      <Header />
      <div className="ml-60 bg-[#F4F7FC]">
        <UserCSideBar statusCount={statusCount}/>
        <div className="container pt-16">
          <Routes>
            <Route
              path="/"
              element={<Tasks type="waitingOfferTasks" setStatusCount={setStatusCount} />}
            />
            <Route path="/edit-tasks" element={<Tasks type="editTasks" setStatusCount={setStatusCount} />} />
            <Route path="/on-going" element={<Tasks type="onGoingTasks" setStatusCount={setStatusCount} />} />
            <Route path="/my-tasks" element={<Tasks type="myTasks" setStatusCount={setStatusCount} />} />
            <Route path="/task/:id" element={<TaskDetails />} />
            <Route path="/wallet" element={<Wallet />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/changepass" element={<ChangePass />} />
          </Routes>
        </div>
      </div>
    </>
  );
};

export default UserC;
