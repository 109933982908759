import React, { useState } from "react";
import "@trendmicro/react-sidenav/dist/react-sidenav.css";

import { FaFlag } from "react-icons/fa";
import { BiLogOut } from "react-icons/bi";
import { AiFillSetting } from "react-icons/ai";
import { GiProcessor } from "react-icons/gi";
import { RiTimerLine } from "react-icons/ri";
import { LiaTasksSolid } from "react-icons/lia";
import { SlWallet } from "react-icons/sl";
import { FaCircleArrowRight } from "react-icons/fa6";
import { CiEdit } from "react-icons/ci";

import RemoveCookie from "../../../hooks/removeCookie";

import SideNav, { NavItem, NavIcon, NavText } from "@trendmicro/react-sidenav";
import { useLocation, useNavigate } from "react-router";

function logout() {
  localStorage.removeItem("UserCData");
  localStorage.removeItem("loggedUserName");
  RemoveCookie("UserC");
  window.location.href = "/";
}

const navItems = [
  {
    navigate: "/",
    icon: RiTimerLine,
    label: "Action Needed",
  },
  { navigate: "/edit-tasks", icon: CiEdit, label: "Edits Tasks" },
  { navigate: "/on-going", icon: FaCircleArrowRight, label: "On Going Tasks" },
  { navigate: "/my-tasks", icon: LiaTasksSolid, label: "My Tasks" },
  { navigate: "/wallet", icon: SlWallet, label: "Wallet" },
  {
    navigate: "/settings",
    icon: AiFillSetting,
    label: "Settings",
  },
];

const UserASideBar = ({ statusCount }) => {
  const [isVisible, setIsVisible] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <SideNav
      defaultExpanded={true}
      style={{ position: "fixed" }}
      className="bg-white mt-[4.2rem] py-4 pr-4 shadow-sm"
    >
      <SideNav.Nav>
        {navItems.map((item) => (
          <NavItem
            style={{ height: 36 }}
            key={item.navigate}
            className={`relative flex items-center rounded-sm ${
              location.pathname === item.navigate
                ? "bg-[#F3F8FF] hover:bg-[#F3F8FF]"
                : "hover:bg-gray-50"
            }`}
            eventKey="users"
            onClick={() => navigate(item.navigate)}
          >
            {location.pathname === item.navigate && (
              <div
                style={{ height: 36, marginTop: 7 }}
                className="rounded-sm absolute top-0 w-1 bg-[#0058FF]"
              ></div>
            )}

            <NavIcon
              style={{
                display: "flex",
                alignItems: "center",
                height: 20,
                marginTop: 14,
              }}
            >
              <item.icon
                className="mx-auto"
                color={
                  location.pathname === item.navigate ? "#0058FF" : "#171725"
                }
              />
            </NavIcon>
            <NavText>
              <p
                style={{
                  color:
                    location.pathname === item.navigate ? "#0058FF" : "#171725",
                }}
              >
                {item.label}
              </p>
            </NavText>
            {(item.label === "Action Needed" || item.label === "On Going Tasks" || item.label === "Edits Tasks") && (<div
              style={{
                position: "absolute",
                top: 15,
                bottom: 15,
                right: 20,
                left: 180,
                width: 20,
                height: 20,
                borderRadius: "50%",
                backgroundColor: "red",
                color: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: 12,
              }}
            >
              {
                item.label === "Action Needed" ? 
                statusCount.waitingOffer 
                : item.label === "On Going Tasks" ? 
                statusCount.onGoing 
                : item.label === "Edits Tasks" ? 
                statusCount.edit 
                : ""
              }
            </div>)}
          </NavItem>
        ))}
        <NavItem
          style={{ height: 36 }}
          className={`rounded-sm flex items-center hover:bg-gray-50`}
          eventKey="home"
          onClick={logout}
        >
          <NavIcon
            style={{
              display: "flex",
              alignItems: "center",
              height: 20,
              marginTop: 14,
            }}
          >
            <BiLogOut className="mx-auto" color="#171725" />
          </NavIcon>
          <NavText>
            <p className="" style={{ color: "#171725" }}>
              LOGOUT
            </p>
          </NavText>
        </NavItem>
      </SideNav.Nav>
    </SideNav>
  );
};

export default UserASideBar;
