import React, { useEffect, useState } from "react";
import { getRowClass, getStatusClass } from "../../util/colors";
import { Link } from "react-router-dom";
import { calculateCountdown } from "../../util/calculateCountdown";
import { RiTimerLine } from "react-icons/ri";
import { format } from "date-fns";

const role = JSON.parse(localStorage.getItem("user"))?.user_role;
const user = JSON.parse(localStorage.getItem("user"));
const Task = ({ task, index, type }) => {
  const [countdown, setCountdown] = useState({});

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, "dd/MM/yyyy HH:mm");
  };

  useEffect(() => {
    if (task && task.deadline) {
      setCountdown(calculateCountdown(task.deadline, false));
    }
    const interval = setInterval(() => {
      if (task && task.deadline) {
        setCountdown(calculateCountdown(task.deadline, false));
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [task]);

  return (
    <tr
      className={`${task.priority === "high" ? "bg-red-200" : (task.taskStatus.slug === "waiting-offer" || task.taskStatus.slug === "approved") && role !== "customerService" && task.offers?.length > 0 ? "bg-green-200" : "bg-white"} ${
        index !== 0 && "border-t-4 border-[#F4F7FC]"
      }`}
    >
      <td className="px-2 py-1">{formatDate(task.createdAt).split(" ")[0]}</td>
      <td className="cursor-pointer px-4 py-1">
        {role === "freelancer" &&
        task.freelancer?._id !== user?._id &&
        task?.taskStatus.slug !== "waiting-offer" &&
        task?.taskStatus.slug !== "pending" &&
        task?.taskStatus.slug !== "approved" ? (
          <p>{task.serialNumber}</p>
        ) : (
          <Link
            className={`hover:underline no-underline text-gray-700`}
            to={`/task/${task?._id}`}
          >
            {task.serialNumber}
          </Link>
        )}
      </td>
      <td className="cursor-pointer px-4 py-1">
        {role === "freelancer" &&
        task.freelancer?._id !== user?._id &&
        task?.taskStatus.slug !== "waiting-offer" &&
        task?.taskStatus.slug !== "pending" &&
        task?.taskStatus.slug !== "approved" ? (
          <p>{task.title}</p>
        ) : (
          <Link
            className={`hover:underline no-underline text-gray-700`}
            to={`/task/${task?._id}`}
          >
            {task.title}
          </Link>
        )}
      </td>
      {role !== "freelancer" && role !== "academicReviewer" && (
        <td className="px-2 py-1 text-sm">{task.created_by.fullname}</td>
      )}
      {(role === "admin" || role === "customerService") && (
        <td className="px-2 py-1 text-sm">{task.client?.clientname}</td>
      )}
      {(role === "admin" || role === "specialistService") && (
        <td className="px-2 py-1 text-sm">
          {task.freelancer ? task.freelancer.freelancername : "-"}
        </td>
      )}
      {role !== "customerService" && role !== "academicReviewer" && (
        <td className="px-2 py-1 text-sm">
          {role === "freelancer" && task.freelancer?._id !== user?._id
            ? "-"
            : role === "freelancer"
            ? task.cost / task.freelancer.currency.priceToEGP
            : task.cost || 0}
        </td>
      )}
      {(role === "admin" || role === "customerService") && (
        <td className="px-2 py-1 text-sm">{task.paid || 0}</td>
      )}

      {role === "admin" && (
        <td className="px-2 py-1 text-sm">{task.profit_amount || 0}</td>
      )}
      <td className="px-0 py-1 text-sm">
        <p className="m-0 p-0">
          {task.deadline ? formatDate(task.deadline) : "N/A"}
        </p>
        {task?.taskStatus.slug !== "delivered" &&
        task?.taskStatus.slug !== "rejected" &&
        task?.taskStatus.slug !== "cancel" &&
        task?.taskStatus.slug !== "not-available" ? (
          <p
            className={`flex items-center justify-center font-bold m-0 p-0 ${
              countdown.days <= 1 && countdown.days >= 0
                ? "text-yellow-500 animate-pulse"
                : countdown.days > 1
                ? "text-green-500"
                : countdown.seconds <= 0
                ? "text-red-500 animate-pulse"
                : ""
            }`}
          >
            <RiTimerLine className="font-bold"></RiTimerLine>
            {countdown.countdown}
          </p>
        ) : (
          ""
        )}
      </td>
      <td className="px-2 py-1">
        {role === "freelancer" &&
        task.freelancer?._id !== user?._id &&
        task?.taskStatus.slug !== "waiting-offer" &&
        task?.taskStatus.slug !== "approved" &&
        task?.taskStatus.slug !== "pending" &&
        task?.taskStatus.slug !== "offer-submitted" ? (
          <p>Expired</p>
        ) : (
          <div
            className={`w-full rounded-md px-2 py-1 text-xs font-bold ${getRowClass(
              task?.taskStatus?.statusname
            )} ${getStatusClass(task?.taskStatus?.statusname)}`}
          >
            {role === "freelancer" &&
            (task?.taskStatus?.statusname === "approved" ||
              task?.taskStatus?.statusname === "pending")
              ? "Waiting Offer"
              : task?.taskStatus?.statusname === "edit"
              ? `Edit - ${task.lastEdit}`
              : type === "editTasks" &&
                task?.taskStatus?.statusname === "on going"
              ? `Returned`
              : task?.taskStatus
              ? task?.taskStatus.statusname.charAt(0).toUpperCase() +
                task?.taskStatus.statusname.slice(1)
              : "N/A"}
          </div>
        )}
      </td>
    </tr>
  );
};

export default Task;
