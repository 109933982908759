import React, { useState } from "react";
import ApexCharts from "react-apexcharts";

const Breakdown = ({ source, data, sectionClasses }) => {
  const [completed, setCompleted] = useState(false);

  const options = {
    labels: source === "statuses" ? data.map((item) => item.name) : !completed ? data.sortedSpecialities.map((item) => item.name) : data.sortedCompletedSpecialities.map((item) => item.name),
    series: source === "statuses" ? data.map((item) => item.percentage) : !completed ? data.sortedSpecialities.map((item) => item.percentage) : data.sortedCompletedSpecialities.map((item) => item.percentage),
    chart: {
      type: "donut",
    },
    legend: {
      show: true,
      position: "bottom",
    },
    colors: ["#1abc9c", "#f1c40f", "#e74c3c", "#2980b9", "#9b59b6", "#bdc3c7"],
  };

  return (
    <div className={`${sectionClasses} w-1/2 font-medium pt-4 px-4`}>
      <div className="flex items-center justify-between">
        <p className="text-2xl capitalize">{source}</p>
        {source === "specilaities" && (
          <label className="inline-flex relative items-center mr-5">
          <input type="checkbox" className="sr-only peer" checked={completed} onChange={() => setCompleted(!completed)} />
          <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
          <span className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">{"Completed"}</span>
        </label>
        )}
      </div>
      <div className="flex justify-center items-center">
        <ApexCharts options={options} series={options.series} type="donut" />
      </div>
    </div>
  );
};

export default Breakdown;
