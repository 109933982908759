import React, { useEffect, useReducer, useState } from "react";
import {
  validate,
  VALIDATOR_EMAIL,
  VALIDATOR_MINLENGTH,
} from "../../../../util/validators";
import axios from "axios";
import LoadingSpinner from "../../../../LoadingSpinner/LoadingSpinner";
import ErrorModal from "../../../../LoadingSpinner/ErrorModal";
import { TiArrowBack } from "react-icons/ti";
import { useNavigate } from "react-router";
import SpecialtySelector from "../../../specialty/multipeSpecialty";

//fullName validation
const fullNameReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE":
      return {
        ...state,
        value: action.fullName,
        isvalid: validate(action.fullName, action.validators),
      };
    case "TOUCH":
      return {
        ...state,
        isTouched: true,
      };
    default:
      return state;
  }
};

//number validation
const numberReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE":
      return {
        ...state,
        value: action.number,
        isvalid: validate(action.number, action.validators),
      };
    case "TOUCH":
      return {
        ...state,
        isTouched: true,
      };
    default:
      return state;
  }
};
//email validation
const emailReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE":
      return {
        ...state,
        value: action.email,
        isvalid: validate(action.email, action.validators),
      };
    case "TOUCH":
      return {
        ...state,
        isTouched: true,
      };
    default:
      return state;
  }
};

const AddFreeLancer = () => {
  const [currencies, setCurrencies] = useState([]);
  const [countries, setCountries] = useState([]);
  const [specialities, setSpecialities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const [paymentMethod, setPaymentMethod] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [language, setLanguage] = useState("");

  useEffect(() => {
    let timerId;
    if (loading) {
      setIsLoading(true);
      timerId = setTimeout(async () => {
        await axios
          .get(`${process.env.REACT_APP_BACKEND_URL}:5001/api/speciality/`)
          .then((res) => {
            setSpecialities(res.data.specialities);
          });
        setLoading(false);
        setIsLoading(false);
      });
      timerId = setTimeout(async () => {
        await axios
          .get(
            `${process.env.REACT_APP_BACKEND_URL}:5001/api/currency/valid/list`
          )
          .then((res) => {
            setCurrencies(res.data.currencies);
          });
        setLoading(false);
        setIsLoading(false);
      });
      timerId = setTimeout(async () => {
        await axios
          .get(`${process.env.REACT_APP_BACKEND_URL}:5001/api/country/`)
          .then((res) => {
            setCountries(res.data.countries);
          });
        setLoading(false);
        setIsLoading(false);
      });
    }
    return () => clearTimeout(timerId);
  }, [loading]);

  //currency value
  const [currency, setCurrency] = useState("");
  const currencyChangeHandler = (newOne) => {
    setCurrency(newOne);
  };
  //speciality value
  const [speciality, setSpeciality] = useState("");

  //fullName validation
  const [fullNameState, dispatch2] = useReducer(fullNameReducer, {
    value: "",
    isvalid: false,
    isTouched: false,
  });

  const fullNameChangeHandler = (event) => {
    dispatch2({
      type: "CHANGE",
      fullName: event.target.value,
      validators: [VALIDATOR_MINLENGTH(3)],
    });
  };
  const fullNameTouchHandler = () => {
    dispatch2({
      type: "TOUCH",
    });
  };

  //Number validation
  const [numberState, dispatch5] = useReducer(numberReducer, {
    value: "",
    isvalid: false,
    isTouched: false,
  });

  const numberChangeHandler = (event) => {
    dispatch5({
      type: "CHANGE",
      number: event.target.value,
      validators: [VALIDATOR_MINLENGTH(11)],
    });
  };
  const numbertouchHandler = () => {
    dispatch5({
      type: "TOUCH",
    });
  };

  //email validation
  const [emailState, dispatch7] = useReducer(emailReducer, {
    value: "",
    isvalid: false,
    isTouched: false,
  });

  const emailChangeHandler = (event) => {
    dispatch7({
      type: "CHANGE",
      email: event.target.value,
      validators: [VALIDATOR_EMAIL()],
    });
  };
  const emailTouchHandler = () => {
    dispatch7({
      type: "TOUCH",
    });
  };

  //country value
  const [country, setCountry] = useState("");
  const countryChangeHandler = (newOne) => {
    setCountry(newOne);
  };

  //password validation
  const passwordReducer = (state, action) => {
    switch (action.type) {
      case "CHANGE":
        return {
          ...state,
          value: action.password,
          isvalid: validate(action.password, action.validators),
        };
      case "TOUCH":
        return {
          ...state,
          isTouched: true,
        };
      default:
        return state;
    }
  };

  //password validation
  const [passwordState, dispatch3] = useReducer(passwordReducer, {
    value: "",
    isvalid: false,
    isTouched: false,
  });

  const passwordChangeHandler = (event) => {
    dispatch3({
      type: "CHANGE",
      password: event.target.value,
      validators: [VALIDATOR_MINLENGTH(3)],
    });
  };
  const passwordTouchHandler = () => {
    dispatch3({
      type: "TOUCH",
    });
  };

  const userNameReducer = (state, action) => {
    switch (action.type) {
      case "CHANGE":
        return {
          ...state,
          value: action.userName,
          isvalid: validate(action.userName, action.validators),
        };
      case "TOUCH":
        return {
          ...state,
          isTouched: true,
        };
      default:
        return state;
    }
  };

  //userName validation
  const [userNameState, dispatch] = useReducer(userNameReducer, {
    value: "",
    isvalid: false,
    isTouched: false,
  });

  const userNameChangeHandler = (event) => {
    dispatch({
      type: "CHANGE",
      userName: event.target.value,
      validators: [VALIDATOR_MINLENGTH(3)],
    });
  };
  const userNameTouchHandler = () => {
    dispatch({
      type: "TOUCH",
    });
  };

  /////////////////////////////////

  const isFormValid = () => {
    return (
      fullNameState.isvalid &&
      passwordState.isvalid &&
      numberState.isvalid &&
      emailState.isvalid &&
      country &&
      speciality.length > 0 &&
      currency &&
      paymentMethod &&
      accountNumber
    );
  };

  const newFreeLancerSubmitHandler = async (event) => {
    event.preventDefault();

    // Check if the form is valid before proceeding
    if (!isFormValid()) {
      setError("Please fill in all required fields correctly.");
      return;
    }
    // send api request to validate data
    setIsLoading(true);

    const body = {
      name: fullNameState.value,
      userName: userNameState.value,
      password: passwordState.value,
      speciality: speciality,
      language: language,
      phone: numberState.value,
      email: emailState.value,
      country: country,
      currency: currency,
      method: paymentMethod,
      accountNumber,
    };

    try {
      setError(null);
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}:5001/api/freelancer/`,
        body
      );

      const responseData = await response;

      if (!(response.statusText === "OK")) {
        throw new Error(responseData.data.message);
      }
      setError(responseData.data.message);
      setIsLoading(false);
      navigate("/freelancers");
    } catch (err) {
      setIsLoading(false);
      setError(err.message || "SomeThing Went Wrong , Please Try Again .");
    }
    fullNameState.value = "";
    userNameState.value = "";
    passwordState.value = "";
    numberState.value = "";
    emailState.value = "";
    setCountry("");
    setSpeciality([]);
    setCurrency("");
    setLanguage("");
  };

  const errorHandler = () => {
    setError(null);
  };
  
  return (
    <div className="flex flex-col w-full p-3 min-h-[calc(100vh-65px)]">
      <ErrorModal error={error} onClear={errorHandler} />
      {isLoading && <LoadingSpinner asOverlay />}

      <div className="relative flex flex-row justify-center w-full p-1 mb-4">
        <button
          className="absolute top-0 left-0 p-2 text-3xl"
          onClick={() => navigate("/freelancers")}
        >
          <TiArrowBack />
        </button>
        <h2 className="text-center text-2xl font-bold lg:text-3xl">
          Add New FreeLancer
        </h2>
      </div>

      <form
        className="grid grid-cols-2 gap-4 w-4/5 mx-auto"
        onSubmit={newFreeLancerSubmitHandler}
        noValidate
      >
        <div className="flex flex-col w-full">
          <label className="w-full lg:w-1/5 font-bold">Full Name</label>
          <input
            type="text"
            placeholder="Full Name"
            value={fullNameState.value}
            onChange={fullNameChangeHandler}
            onBlur={fullNameTouchHandler}
            isvalid={fullNameState.isvalid.toString()}
            className={`w-full ml-2 rounded-sm lg:w-4/5 p-2 ${
              !fullNameState.isvalid &&
              fullNameState.isTouched &&
              "border-red-500"
            }`}
          />
        </div>

        <div className="flex flex-col w-full">
          <label className="w-full lg:w-1/5 font-bold">Password</label>
          <input
            type="password"
            placeholder="Password"
            value={passwordState.value}
            onChange={passwordChangeHandler}
            onBlur={passwordTouchHandler}
            isvalid={passwordState.isvalid.toString()}
            className={`w-full ml-2 rounded-sm lg:w-4/5 p-2 ${
              !passwordState.isvalid &&
              passwordState.isTouched &&
              "border-red-500"
            }`}
          />
        </div>

        <div className="flex flex-col w-full">
          <label className="w-full lg:w-1/5 font-bold">Phone</label>
          <input
            type="number"
            placeholder="Phone Number"
            value={numberState.value}
            onChange={numberChangeHandler}
            onBlur={numbertouchHandler}
            isvalid={numberState.isvalid.toString()}
            className={`w-full ml-2 rounded-sm lg:w-4/5 p-2 ${
              !numberState.isvalid && numberState.isTouched && "border-red-500"
            }`}
          />
        </div>

        <div className="flex flex-col w-full">
          <label className="w-full lg:w-1/5 font-bold">Email</label>
          <input
            type="email"
            placeholder="Email"
            value={emailState.value}
            onChange={emailChangeHandler}
            onBlur={emailTouchHandler}
            isvalid={emailState.isvalid.toString()}
            className={`w-full ml-2 rounded-sm lg:w-4/5 p-2 `}
          />
        </div>

        <div className="flex flex-col w-full">
          <label className="w-full lg:w-1/5 font-bold">Country</label>
          <select
            id="country"
            name="country"
            className="w-full ml-2 rounded-sm lg:w-4/5 p-2"
            value={country}
            onChange={(event) => countryChangeHandler(event.target.value)}
          >
            <option value="" className="text-gray-400">
              Countries
            </option>
            {countries.map((country) => (
              <option value={country?._id} key={country?._id}>
                {country.countryName}
              </option>
            ))}
          </select>
        </div>

        <div className="flex flex-col w-full">
          <label className="w-full font-bold">Language</label>
          <select
            id="language"
            name="language"
            className="w-full ml-2 rounded-sm lg:w-4/5 p-2"
            onChange={(event) => setLanguage(event.target.value)}
          >
            <option selected disabled value="" className="text-secondary">
              Select Language
            </option>
            <option value="all">All</option>
            <option value="arabic">Arabic</option>
            <option value="english">English</option>
            <option value="other">Other</option>
          </select>
        </div>

        <div className="flex flex-col w-full">
          <label htmlFor="currency" className="w-full lg:w-1/5 font-bold">
            Currency
          </label>

          <select
            id="currencies"
            name="currencies"
            className="w-full ml-2 rounded-sm lg:w-4/5 p-2"
            value={currency}
            onChange={(event) => currencyChangeHandler(event.target.value)}
          >
            <option value="" className="text-gray-400">
              currencies
            </option>
            {currencies.map((currency) => (
              <option value={currency?._id} key={currency?._id}>
                {currency.currencyname}
              </option>
            ))}
          </select>
        </div>

        <div></div>

        <SpecialtySelector 
          specialities={specialities} 
          speciality={speciality} 
          setSpeciality={setSpeciality} 
        />

        <div className="flex flex-col w-full">
          <label className="w-full font-bold">Payment Method</label>
          <input
            type="text"
            placeholder="Payment Method"
            value={paymentMethod}
            onChange={(event) => setPaymentMethod(event.target.value)}
            className="w-full ml-2 rounded-sm lg:w-4/5 p-2"
          />
        </div>

        <div className="flex flex-col w-full">
          <label className="w-full font-bold">Account Number</label>
          <input
            type="text"
            placeholder="Account Number"
            value={accountNumber}
            onChange={(event) => setAccountNumber(event.target.value)}
            className="w-full ml-2 rounded-sm lg:w-4/5 p-2"
          />
        </div>

        <div className="col-span-2 w-full flex items-center justify-center">
          <button
            type="button"
            onClick={newFreeLancerSubmitHandler}
            disabled={!isFormValid()}
            className="bg-cyan-600 text-white rounded py-1 font-bold w-4/5 lg:w-1/5 transition-all hover:bg-cyan-500 active:scale-95 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Add
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddFreeLancer;
