import React, { useEffect, useState } from "react";
import axios from "axios";
import LoadingSpinner from "../../../../LoadingSpinner/LoadingSpinner";
import Filter from "../../../Filter";
import { useNavigate } from "react-router-dom";
import Account from "../../../Account";
import ReactDatePicker from "react-datepicker";
import { FaPlus, FaSearch } from "react-icons/fa";
import { useDebounce } from "use-debounce";
import Pagination from "../../../Pagination";

const Accounts = () => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(30);
  const [count, setCount] = useState();
  const [accounts, setAccounts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const [searchName, setSearchName] = useState("");
  const [search, setSearch] = useState("");
  const [filterOpen, setFilterOpen] = useState(false);
  const [accountType, setAccountType] = useState("freelancer");
  const [searchFilterData, setSearchFilterData] = useState(true);
  const [accountTypeFilterData, setAccountTypeFilterData] = useState(false);
  const navigate = useNavigate();

  const [datePickerOpen] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [sort, setSort] = useState(null);

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      try {
        const params = { page, limit, search, sort, type: "freelancer" };
        const accountResponse = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}:5001/api/account/`,
          { params }
        );
        console.log(accountResponse.data);
        setAccounts(accountResponse.data.accounts);
        setCount(accountResponse.data.count);
        setIsLoading(false);
      } catch (error) {
        if (error.response) {
          setError({ type: "error", message: error.response.data.err });
        } else {
          setError({ type: "error", message: error.message });
        }
      } finally {
        setIsLoading(false);
      }
    })();
  }, [page, limit, search, sort, accountType]);

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  return isLoading ? (
    <LoadingSpinner asOverlay />
  ) : (
    <>
      {/* <Filter
        filterOpen={filterOpen}
        setFilterOpen={setFilterOpen}
        clear={() => setAccountType(null)}
      >
        <select
          id="accountType"
          name="accountType"
          className=""
          value={accountType}
          onChange={(e) => {
            setAccountType(e.target.value);
          }}
        >
          <option selected disabled value="">
            Select Type
          </option>
          <option value="freelancer">FreeLancer</option>
          <option value="client">Client</option>
        </select>
      </Filter> */}
      <div
        className={`transition-all min-h-[calc(100vh-65px)] ${
          filterOpen ? "ml-44" : "ml-16"
        } py-4 flex flex-col space-y-2`}
      >
        <div className="flex justify-between items-center my-8">
          <h1 className="text-2xl">System Accounts</h1>
        </div>
        <div className="bg-gray-100 px-8 py-4 rounded-sm drop-shadow">
          <div className="flex justify-between items-center">Team Members</div>
          <div className="flex justify-between items-center my-4">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                setSearch(e.target.search.value);
              }}
              className="w-1/3 relative"
            >
              <button type="submit" className="absolute top-3 right-3">
                <FaSearch />
              </button>
              <input
                name="search"
                type="text"
                value={searchName}
                onChange={(e) => setSearchName(e.target.value)}
                placeholder={"Search By Name or ID"}
              />
            </form>
            <select
              id="sort"
              name="sort"
              className="w-1/4"
              value={sort}
              onChange={(e) => {
                setSort(e.target.value);
              }}
            >
              <option selected disabled value="" className="text-secondary">
                Sort By
              </option>
              <option value="balance">Balance</option>
            </select>
          </div>
          {accounts && accounts.length > 0 ? (
            <>
              {accounts.map((account, index) => (
                <Account key={index} user={account} />
              ))}
              <Pagination
                currentPage={page}
                totalPages={Math.ceil(count / limit)}
                onNextPage={() => setPage(page + 1)}
                onPreviousPage={() => setPage(page - 1)}
                onFirstPage={() => setPage(1)}
                onLastPage={() => setPage(Math.ceil(count / limit))}
              />
            </>
          ) : (
            <div className="text-center">
              <h2>There Is No Accounts</h2>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Accounts;
