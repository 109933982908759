import axios from "axios";
import GetCookie from "./hooks/getCookie";

const role = JSON.parse(localStorage.getItem("user"))?.user_role;
let token = "";
if (role === "admin") {
  token = GetCookie("AdminToken");
} else if (role === "customerService") {
  token = GetCookie("UserA");
} else if (role === "specialistService") {
  token = GetCookie("UserB");
} else if (role === "freelancer") {
  token = GetCookie("UserC");
} else if (role === "academicReviewer") {
  token = GetCookie("UserD");
} else {
  token = "";
}

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}:5001/api`,
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

export default instance;
