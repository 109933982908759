import React from "react";
import { FaWhatsapp } from "react-icons/fa";
import { HiExternalLink } from "react-icons/hi";
import { useParams, useNavigate, Link } from "react-router-dom";

const TopUsers = ({ sectionClasses, data, role }) => {
  const users = data;
  const navigate = useNavigate();

  return (
    <div className={`${sectionClasses} w-1/2 font-medium pt-4 px-4`}>
      <p className="text-2xl capitalize">
        Top {users.length} {role}
      </p>
      <div className="">
        {users.map((user, index) => (
          <div key={user.name + index} className="w-full h-full">
            <div className="w-full flex justify-between py-3">
              <div className="w-3/5 flex items-center space-x-4">
                <div className="w-8 h-8 bg-violet-100 rounded-full flex items-center justify-center">
                  {user.name.charAt(0).toLocaleUpperCase()}
                </div>
                <span className="">
                  {user.name}
                  <br />
                  <span className="text-xs text-gray-500">
                    {user.by}: {user.value} {role === "freelancers" ? "" : "%"}
                  </span>
                </span>
              </div>
              <div className="w-1/5 flex items-center justify-end">
                <HiExternalLink onClick={() => {
                  role === "freelancers" ? navigate("/freeLancer/" + user.id) : navigate("/client/" + user.id)
                }} className="cursor-pointer text-2xl" />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TopUsers;
