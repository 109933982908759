import React, { useEffect, useState } from "react";
import WaitingOffer from "./Progress/WaitingOffer";
import Assigned from "./Progress/Assigned";
import Ongoing from "./Progress/Ongoing";
import Delivered from "./Progress/Delivered";
import Approved from "./Progress/Approved";

const Status = ({ status, task, setTask, offer, setOffer }) => {
  const availableStatuses = [
    "waiting offer",
    "rejected",
    "assigned",
    "on going",
    "delivered",
    "edit",
    "approved",
    "pending",
  ];
  const [statusState, setStatusState] = useState(status);

  useEffect(() => console.log({ offers: task.offers }), [task]);

  const renderStatus = () => {
    switch (statusState) {
      case "waiting offer":
        return (
          <WaitingOffer
            taskId={task?._id}
            setStatus={setStatusState}
            offers={task.offers}
            setTask={setTask}
            task={task}
          />
        );

      case "pending":
        return (
          <WaitingOffer
            taskId={task?._id}
            setStatus={setStatusState}
            offers={task.offers}
            setTask={setTask}
            task={task}
          />
        );

      case "rejected":
        return (
          <WaitingOffer
            taskId={task?._id}
            rejected
            setStatus={setStatusState}
            offers={task.offers}
            setTask={setTask}
            task={task}
          />
        );
      case "assigned":
        return (
          <Assigned
            taskId={task?._id}
            freelancer={task.freelancer?.freelancername}
            setStatus={setStatusState}
            setTask={setTask}
            task={task}
          />
        );

      case "on going":
        return (
          <Ongoing
            taskId={task?._id}
            freelancer={task.freelancer?.freelancername}
            setStatus={setStatusState}
            setTask={setTask}
            task={task}
          />
        );

      case "delivered":
        return (
          <Delivered
            taskId={task?._id}
            edits={task.edits}
            setTask={setTask}
            task={task}
          />
        );
      case "edit":
        return (
          <Delivered
            taskId={task?._id}
            edits={task.edits}
            setTask={setTask}
            task={task}
          />
        );

      case "approved":
        return (
          <Approved
            taskId={task?._id}
            setStatus={setStatusState}
            offers={task.offers}
            setTask={setTask}
            task={task}
          />
        );
      default:
        return;
    }
  };

  return (
    <div
      className={`${
        !availableStatuses.includes(statusState) && "hidden"
      } w-full max-w-5xl 2xl:max-w-6xl mx-auto bg-white shadow rounded p-2`}
    >
      {/* <h1 className="text-2xl text-gray-600 font-semibold mb-0">Status</h1>
      <p className="mb-2 p-0 text-gray-400 font-semibold text-sm capitalize">
        {statusState.split("-").join(" ")}
      </p> */}
      {renderStatus()}
    </div>
  );
};

export default Status;
