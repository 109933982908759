import React, { useState } from "react";
import "./UserD.css";
import UserDSideBar from "./UserDSideBar/UserDSideBar";
import { Route, Routes } from "react-router-dom";
import Header from "../Header";

import Tasks from "../UserD/Pages/Tasks/Tasks";
import TaskDetails from "../UserD/Pages/Tasks/TaskDetails";
import Settings from "../UserD/Pages/Settings/Settings";
import ChangePass from "./Pages/Settings/ChangePass";
import PendingTasks from "./Pages/Tasks/PendingTasks";

const UserD = () => {
  const [pendingTasksCount, setPendingTasksCount] = useState(0);

  return (
    <>
      <Header />
      <div className="ml-60 bg-[#F4F7FC]">
        <UserDSideBar pendingTasksCount={pendingTasksCount}/>
        <div className="container pt-16">
          <Routes>
            <Route path="/" element={<PendingTasks setPendingTasksCount={setPendingTasksCount}/>} />
            <Route path="/yourtasks" element={<Tasks />} />
            <Route path="/task/:id" element={<TaskDetails />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/changepass" element={<ChangePass />} />
          </Routes>
        </div>
      </div>
    </>
  );
};

export default UserD;
