export const calculateCountdown = (deadline, skipSeconds = false) => {
  const now = new Date().getTime();
  const distance = new Date(deadline).getTime() - now;
  // if (distance < 0) {
  //   return "Overdue";
  // }
  const days = Math.floor(distance / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((distance % (1000 * 60)) / 1000);
  // return `${days}d ${hours}h ${minutes}m ${skipSeconds ? "" : `${seconds}s`}`;
  return {
    countdown: `${days > 0 ? days : -days}d ${hours > 0 ? hours : -hours}h ${minutes > 0 ? minutes : -minutes}m`,
    days,
    hours,
    minutes,
    seconds
  }
};
