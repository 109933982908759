import React, { useState } from "react";
import ReactDatePicker from "react-datepicker";

const DateFilter = ({
  startDate,
  onChange,
  endDate,
  datePickerOpen,
  dateHandler,
}) => {
  const [selected, setSelected] = useState(null);
  return (
    <div className="flex items-center space-x-4">
      <div className="flex items-center">
        {["day", "week", "month"].map((item) => (
          <button
            key={item}
            type="button"
            onClick={() => {
              selected !== item ? setSelected(item) : setSelected(null);
              switch (item) {
                case "day":
                  const dayStart = new Date();
                  dayStart.setHours(0, 0, 0, 0);
                  const dayEnd = new Date(dayStart.getTime());
                  dayEnd.setHours(23, 59, 59, 999);
                  startDate={dayStart}
                  endDate={dayEnd}
                  onChange=onChange([dayStart, dayEnd]);
                  break;

                case "week":
                  const weekStart = new Date();
                  weekStart.setDate(weekStart.getDate() - 7);
                  weekStart.setHours(0, 0, 0, 0);
                  const weekEnd = new Date();
                  weekEnd.setHours(23, 59, 59, 999);
                  startDate={weekStart}
                  endDate={weekEnd}
                  onChange=onChange([weekStart, weekEnd]);
                  break;

                case "month":
                  const monthStart = new Date();
                  monthStart.setMonth(monthStart.getMonth() - 1);
                  monthStart.setHours(0, 0, 0, 0);
                  const monthEnd = new Date();
                  monthEnd.setHours(23, 59, 59, 999);
                  startDate={monthStart}
                  endDate={monthEnd}
                  onChange=onChange([monthStart, monthEnd]);
                  break;

                default:
                  break;
              }
            }}
            className={`py-1.5 px-2.5 flex justify-center border rounded-sm text-sm font-medium capitalize ${
              selected === item ? "text-white bg-primary" : "bg-white"
            }`}
          >
            {item}
          </button>
        ))}
      </div>
      <ReactDatePicker
        className="relative rounded border border-gray-300 cursor-pointer text-gray-400 w-64 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-transparent"
        selected={endDate}
        selectsEnd={endDate}
        onChange={onChange}
        onCalendarClose={dateHandler}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        showMonthDropdown
        showYearDropdown
        inline={datePickerOpen}
        dateFormat="MMM, yyyy"
        placeholderText="Select Date Range"
        showIcon
        toggleCalendarOnIconClick
        calendarIconClassname="mr-4 cursor-pointer absolute z-10 top-0.5"
      />
    </div>
  );
};

export default DateFilter;
