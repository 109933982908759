import React, { useEffect, useState } from "react";
import ErrorModal from "../../../../LoadingSpinner/ErrorModal";
import LoadingSpinner from "../../../../LoadingSpinner/LoadingSpinner";
import { TiArrowBack } from "react-icons/ti";
import { Field, Form, Formik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import axios from "../../../../axios";
import ReactSelect from "react-select";
import Creatable from "react-select/creatable";

const AddSpending = ({}) => {
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [accounts, setAccounts] = useState(null);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [createdAt, setCreatedAt] = useState(new Date());
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const bankAccountsResponse = await axios.get("/bank");
        setAccounts(bankAccountsResponse.data);
        setCategories([
          "Salaries",
          "Marketing Costs",
          "Operational Costs",
          "Legal & Financial Services"
        ]);
        // const categoriesResponse = await axios.get("/spending/category");
        // setCategories(categoriesResponse.data.spendingCategory);
      } catch (error) {
        if (error.response) {
          setMessage({ type: "error", message: error.response.data.err });
        } else {
          setMessage({ type: "error", message: error.message });
        }
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  const subCategoriesHandler = async (category, formik) => {
    try {
      //   setSubCategoryLoading(true);
      formik.setFieldValue("category", "");
      const subCats = {
        "Salaries": [
          "Employee Salaries",
          "Employee Benefits (Health Insurance, etc.)",
          "Freelancer Payment",
          "Bonuses & Activities",
        ],
        "Marketing Costs": [
          "Social Media",
          "Ads Credit",
          "Percentage",
          "Promotional Materials",
        ],
        "Operational Costs": [
          "Office Rent",
          "Office Supplies",
          "System Development",
          "Software Licenses ( HR, CRM, Tools)",
          "Utilities (Electricity, internet etc.)",
          "Logistics",
          "Maintenance",
        ],
        "Legal & Financial Services": [
          "Legal Fees",
          "Taxes",
          "Government Fees",
          "Transfer Charges",
          "Donations & Charity",
        ]
    }
      // const subCategoriesResponse = await axios.get("/spending/subCategory", {
      //   params: { category },
      // });
      // setSubCategories(subCategoriesResponse.data.spendingSubCategory);
      setSubCategories(subCats[category]);
      formik.setFieldValue("category", category);
    } catch (error) {
      if (error.response) {
        setMessage({ type: "error", message: error.response.data.err });
      } else {
        setMessage({ type: "error", message: error.message });
      }
    } finally {
      //   setSubCategoryLoading(false);
    }
  };

  const onSubmit = async (values) => {
    try {
      setIsLoading(true);
      await axios.post("/spending", values);
    } catch (error) {
      if (error.response) {
        setMessage({ type: "error", message: error.response.data.err });
      } else {
        setMessage({ type: "error", message: error.message });
      }
    } finally {
      setIsLoading(false);
      navigate("/spendings");
    }
  };

  return (
    <div className="flex flex-col w-full p-3 min-h-[calc(100vh-65px)]">
      {message && (
        <ErrorModal message={message} onClear={() => setMessage(null)} />
      )}
      <div className="relative flex flex-row justify-center w-full p-1 mb-4">
        <Link to="/spendings" className="absolute top-0 left-0 p-2 text-3xl">
          <TiArrowBack />
        </Link>
        <h2 className="text-center text-2xl font-bold lg:text-3xl">
          Add Spend
        </h2>
      </div>
      {isLoading ? (
        <LoadingSpinner asOverlay />
      ) : (
        <Formik
          initialValues={{
            category: "",
            subCategory: "",
            bankId: "",
            amount: "",
            description: "",
            createdAt: createdAt,
          }}
          onSubmit={onSubmit}
        >
          {(formik) => (
            <Form className="mx-auto max-w-2xl grid grid-cols-2 gap-4 mt-4">
              <div className="w-full ">
                <label
                  htmlFor="category"
                  className="block mb-2 text-lg font-medium text-gray-700"
                >
                  Category:
                </label>
                <Creatable
                  options={categories.map((category) => ({
                    label: category,
                    value: category,
                  }))}
                  onChange={(e) => subCategoriesHandler(e.value, formik)}
                  className="w-full rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                  name="from"
                />
              </div>
              <div className="w-full ">
                <label
                  htmlFor="subCategory"
                  className="block mb-2 text-lg font-medium text-gray-700"
                >
                  Sub-Category:
                </label>
                <Creatable
                  isDisabled={!formik.values.category}
                  options={subCategories.map((subCategory) => ({
                    label: subCategory,
                    value: subCategory,
                  }))}
                  onChange={(e) => formik.setFieldValue("subCategory", e.value)}
                  className="w-full rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                  name="subCategory"
                />
              </div>
              <div className="w-full ">
                <label
                  htmlFor="bankId"
                  className="block mb-2 text-lg font-medium text-gray-700"
                >
                  Bank Account:
                </label>
                <ReactSelect
                  options={accounts.map((account) => ({
                    label: `${account.title} - ${account.currency.currencyname}`,
                    value: account?._id,
                  }))}
                  onChange={(e) => formik.setFieldValue("bankId", e.value)}
                  className="w-full rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                  name="bankId"
                />
              </div>
              <div className="w-full ">
                <label
                  htmlFor="amount"
                  className="block mb-2 text-lg font-medium text-gray-700"
                >
                  Amount:
                </label>
                <Field
                  type="number"
                  id="amount"
                  name="amount"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                />
              </div>
              <div className="col-span-2">
                <label
                  htmlFor="description"
                  className="block mb-2 text-lg font-medium text-gray-700"
                >
                  Description:
                </label>
                <Field
                  type="textarea"
                  id="description"
                  name="description"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                />
              </div>
              <div className="col-span-2">
                <label
                  htmlFor="createdAt"
                  className="block mb-2 text-lg font-medium text-gray-700"
                >
                  Date:
                </label>
                <Field
                  type="date"
                  id="createdAt"
                  name="createdAt"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                />
              </div>
              <div className="col-span-2 flex items-center justify-center">
                <button
                  type="submit"
                  className="px-4 py-2 text-lg font-medium text-white bg-blue-500 rounded-md hover:bg-blue-600"
                >
                  Add Spending
                </button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default AddSpending;
