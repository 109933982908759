import React from "react";
import Pagination from "../Pagination";
import Task from "./Task";

const role = JSON.parse(localStorage.getItem("user"))?.user_role;
const TasksTable = ({
  tasks,
  pagination = false,
  page,
  totalPages,
  onFirstPage,
  onLastPage,
  onNextPage,
  onPreviousPage,
  type,
}) => {
  return (
    <>
      <table className="table-auto w-full rounded-lg overflow-hidden text-center drop-shadow">
        <thead>
          <tr className="drop-shadow bg-white text-cyan-600">
            <th className="px-2 py-3 font-medium text-sm">Created</th>
            <th className="px-2 py-3 font-medium text-sm">ID</th>
            <th className="px-2 py-3 font-medium text-sm">Title</th>
            {role !== "freelancer" && role !== "academicReviewer" && (<th className="px-2 py-3 font-medium text-sm">By</th>)}
            {(role === "admin" || role === "customerService") && (<th className="px-2 py-3 font-medium text-sm">Client</th>)}
            {(role === "admin" || role === "specialistService") && (<th className="px-2 py-3 font-medium text-sm">Freelancer</th>)}
            {role !== "customerService" && role !== "academicReviewer" && (<th className="px-2 py-3 font-medium text-sm">Cost</th>)}
            {(role === "admin" || role === "customerService") && (<th className="px-2 py-3 font-medium text-sm">Paid</th>)}
            {role === "admin" && (<th className="px-2 py-3 font-medium text-sm">Profit</th>)}
            <th className="px-6 py-3 font-medium text-sm">Deadline</th>
            <th className="px-2 py-3 font-medium text-sm">Status</th>
          </tr>
        </thead>
        <tbody>
          {tasks.map((task, index) => (
            <Task key={task?._id} task={task} index={index} type={type} />
          ))}
        </tbody>
      </table>
      {pagination && (
        <Pagination
          currentPage={page}
          totalPages={totalPages}
          onNextPage={onNextPage}
          onPreviousPage={onPreviousPage}
          onFirstPage={onFirstPage}
          onLastPage={onLastPage}
        />
      )}
    </>
  );
};

export default TasksTable;
