import React, { useState } from "react";
import axios from "../../../../../axios";
import LoadingSpinner from "../../../../../LoadingSpinner/LoadingSpinner";
import { emitTaskNotification } from "../../../../../util/io";

const Done = ({ taskId, setStatus, setTask, task }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleDeliver = async () => {
    try {
      setIsLoading(true);
      const response = await axios.post(`/task/action/deliver/${taskId}`);
      emitTaskNotification(
        `Task No: ${task.serialNumber} - Status Changed From Done To Delivered`,
        task
      );
      const responseData = await response;
      console.log(responseData);
      setStatus("delivered");
      setTask((prev) => ({
        ...prev,
        taskStatus: {
          ...prev.taskStatus,
          statusname: "delivered",
          slug: "delivered",
        },
      }));
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      if (error.response) {
        console.log(error.response.data);
      } else {
        console.log(error);
      }
    }
  };

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <div className="flex items-center space-x-4 justify-between">
      <p className="p-0 m-0">Task has been completed and reviewed and ready to be delivered</p>
      <button
        onClick={handleDeliver}
        className="bg-blue-500 text-white px-4 py-2 rounded active:scale-95"
      >
        Deliver
      </button>
    </div>
  );
};

export default Done;
