import React, { useState, useEffect } from "react";
import GetCookie from "./hooks/getCookie";

export const AuthContext = React.createContext();

export function AuthProvider(Props) {
  const [auth, setAuth] = useState({});

  useEffect(() => {
    const admin = GetCookie("AdminToken");
    const usera = GetCookie("UserA");
    const userb = GetCookie("UserB");
    const userc = GetCookie("UserC");
    const userd = GetCookie("UserD");

    //validate token with api
    if (admin) {
      setAuth({
        admin,
      });
    } else if (usera) {
      setAuth({
        usera,
      });
    } else if (userb) {
      setAuth({
        userb,
      });
    } else if (userc) {
      setAuth({
        userc,
      });
    } else if (userd) {
      setAuth({
        userd,
      });
    }
  }, []);

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {Props.children}
    </AuthContext.Provider>
  );
}
