import React, { useEffect, useState } from "react";
import axios from "axios";
import LoadingSpinner from "../../../../LoadingSpinner/LoadingSpinner";
import ErrorModal from "../../../../LoadingSpinner/ErrorModal";

import { useNavigate, useParams } from "react-router-dom";
import { RiDeleteBinFill } from "react-icons/ri";
import { TiArrowBack } from "react-icons/ti";

import { ImCancelCircle } from "react-icons/im";

const CurrencyDetails = () => {
  const [edit, setEdit] = useState(false);

  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  let { id } = useParams();

  const [currency, setCurrency] = useState([]);
  const [currencyName, setCurrencyName] = useState();
  const [currencyPrice, setCurrencyPrice] = useState();
  const [expired, setExpired] = useState();

  useEffect(() => {
    let timerId;
    if (loading) {
      setIsLoading(true);
      timerId = setTimeout(async () => {
        await axios
          .get(`${process.env.REACT_APP_BACKEND_URL}:5001/api/currency/${id}`)
          .then((res) => {
            console.log(res);
            setCurrency(res.data.message);
            setCurrencyName(res.data.message.currencyname);
            setCurrencyPrice(res.data.message.priceToEGP);
          });
        setLoading(false);
        setIsLoading(false);
      });
    }
    return () => clearTimeout(timerId);
  }, [loading]);

  //////////////////////////////////////
  const editCurrencyHandler = async (event) => {
    event.preventDefault();
    // send api request to validate data
    setIsLoading(true);
    try {
      setError(null);
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}:5001/api/currency/${currency?._id}`,
        {
          name: currencyName,
          price: currencyPrice,
          expired: expired,
        }
      );
      const responseData = await response;

      if (!(response.statusText === "OK")) {
        throw new Error(responseData.data.message);
      }
      setError(responseData.data.message);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setError(err.message && "SomeThing Went Wrong , Please Try Again .");
    }
  };

  //delete user
  const deleteCurrencyHandler = async () => {
    setIsLoading(true);
    try {
      setError(null);
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}:5001/api/currency/${id}`
      );
      const responseData = await response;

      setError(responseData.data.message);
      setIsLoading(false);
      window.location.href = "/currency";
    } catch (err) {
      setIsLoading(false);
      setError(err.message || "SomeThing Went Wrong , Please Try Again .");
    }
  };
  //error message
  const errorHandler = () => {
    setError(null);
    window.location.reload(true);
  };

  const navigate = useNavigate();

  return isLoading ? (
    <LoadingSpinner asOverlay />
  ) : (
    <div className="flex flex-col w-full p-3 min-h-[calc(100vh-65px)]">
      <ErrorModal error={error} onClear={errorHandler} />

      <div className="relative flex items-center justify-center w-full p-1 mb-4">
        <button
          className="absolute top-0 left-0 p-2 text-3xl"
          onClick={() => navigate("/currency")}
        >
          <TiArrowBack />
        </button>
        <h2 className="text-center text-2xl font-bold lg:text-3xl">
          Currency Details
        </h2>
      </div>

      <div className="grid grid-cols-2 gap-4 p-4 bg-white rounded drop-shadow">
        <div className="col-span-2 flex justify-end">
          <div className="">
            <button className="text-red-500" onClick={deleteCurrencyHandler}>
              <RiDeleteBinFill className="h-8 w-8" />
            </button>
          </div>
        </div>

        <div>
          <p className="text-gray-500 font-semibold m-0 p-0">Currency Name:</p>
          <p className={!edit ? "font-bold ml-2" : "hidden"}>
            {currency.currencyname}
          </p>
          <div className={edit ? "" : "hidden"}>
            <input
              value={currencyName}
              type="text"
              onChange={(e) => {
                setCurrencyName(e.target.value);
              }}
              className="w-full p-2"
            />
          </div>
        </div>

        {/* Price In EGP */}
        <div>
          <p className="text-gray-500 font-semibold m-0 p-0">Price In EGP:</p>
          <p className={!edit ? "font-bold ml-2" : "hidden"}>
            {currency.priceToEGP}
          </p>
          <div className={edit ? "" : "hidden"}>
            <input
              type="text"
              value={currencyPrice}
              onChange={(e) => {
                setCurrencyPrice(e.target.value);
              }}
              className="w-full p-2"
            />
          </div>
        </div>

        {/* Expired */}
        <div>
          <p className="text-gray-500 font-semibold m-0 p-0">Expired:</p>
          <p className={!edit ? "font-bold ml-2" : "hidden"}>
            {currency.expired}
          </p>
          <div className={edit ? "" : "hidden"}>
            <input
              type="date"
              onChange={(e) => {
                setExpired(e.target.value);
              }}
              className="w-full p-2"
            />
          </div>
        </div>

        <div className="col-span-2 flex justify-center">
          {!edit && (
            <button
              className="text-white bg-blue-500 hover:bg-blue-700 font-bold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-blue-600"
              onClick={() => {
                setEdit(!edit);
              }}
            >
              Edit
            </button>
          )}
          {edit ? (
            <div className="flex space-x-1">
              <button
                disabled={!currencyName && !currencyPrice && !expired}
                className="text-white bg-blue-500 hover:bg-blue-700 font-bold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-blue-600"
                onClick={editCurrencyHandler}
              >
                Submit
              </button>
              <button
                className="text-white bg-red-500 hover:bg-red-700 font-bold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-red-600"
                onClick={() => {
                  setEdit(!edit);
                }}
              >
                Cancel
              </button>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default CurrencyDetails;
