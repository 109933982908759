import io from "socket.io-client";
const userId = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user"))?._id : "";

const socket = io("https://smarteduservices.com:5001", {
// const socket = io("http://localhost:5001", {
  reconnection: true,
  reconnectionAttempts: Infinity,
  reconnectionDelay: 1000,
  transports: ["websocket"],
});

socket.on('connect', () => {
  // console.log('Connected to socket server');
  socket.emit('authenticate', userId);
});
socket.on('reconnect', (attemptNumber) => {
  // console.log('Reconnected to server after ' + attemptNumber + ' attempts');
  // Re-authenticate after reconnection
  socket.emit('authenticate', userId);
});
socket.on('reconnect_attempt', (attemptNumber) => {
  // console.log('Attempting to reconnect:', attemptNumber);
});
socket.on('disconnect', (reason) => {
  // console.log('Disconnected from socket server:', reason);
});

const emit = (text, data) => {
  socket.emit(text, data);
};

export const emitAuthenticate = (id) => {
  emit("authenticate", id);
};

export const emitNotification = (notification) => {
  emit("notification", notification);
};

export const emitTaskNotification = (body, task) => {
  const freelancer = task.freelancer?._id;
  const client = task.client?._id;
  const accepted_by = task.accepted_by?._id;
  const created_by = task.created_by?._id;
  const reviewer = task.reviewed_by?._id;

  const audience = [freelancer, client, accepted_by, created_by, reviewer];

  emitNotification({
    body,
    audience,
    type: "task",
    link: `/task/${task?._id}`,
  });
};

export default socket;