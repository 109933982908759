import React, { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import UserBSideBar from "./UserBSideBar/UserBSideBar";
import TaskDetails from "./Pages/Tasks/TaskDetails";
import FreeLancers from "./Pages/FreeLancers/FreeLancers";
import FreeLancerDetails from "./Pages/FreeLancers/FreeLancerDetails";
import AddFreeLancer from "./Pages/FreeLancers/AddFreeLancer";
import Settings from "./Pages/Settings/Settings";
import PendingTasks from "./Pages/Tasks/PendingTasks";
import YourTasks from "./Pages/Tasks/YourTasks";
import ChangePass from "./Pages/Settings/ChangePass";
import Accounts from "./Pages/Accounts/Accounts";
import AccountDetails from "./Pages/Accounts/AccountDetails";
import Transactions from "./Pages/Transactions/Transactions";
import Specialities from "./Pages/Specialities/Specialities";
import SpecialityDetails from "./Pages/Specialities/specialityDetails";
import AddSpeciality from "./Pages/Specialities/addSpeciality";
import Header from "../Header";

const UserB = () => {
  const [pendingTasksCount, setPendingTasksCount] = useState(0);

  return (
    <>
      <Header />
      <div className="ml-60 bg-[#F4F7FC]">
        <UserBSideBar pendingTasksCount={pendingTasksCount}/>
        <div className="container pt-16">
          <Routes>
            <Route path="/task/:id" element={<TaskDetails />} />
            <Route path="/yourtasks" element={<YourTasks />} />
            <Route path="/" element={<PendingTasks setPendingTasksCount={setPendingTasksCount}/>} />
            <Route path="/freelancers" element={<FreeLancers />} />
            <Route path="/freelancer/:id" element={<FreeLancerDetails />} />
            <Route path="/addfreelancer" element={<AddFreeLancer />} />
            <Route path="/accounts" element={<Accounts />} />
            <Route path="/account/:id" element={<AccountDetails />} />
            <Route path="/transactions" element={<Transactions />} />
            <Route path="/specialities" element={<Specialities />} />
            <Route path="/speciality/:id" element={<SpecialityDetails />} />
            <Route path="/addspeciality" element={<AddSpeciality />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/changepass" element={<ChangePass />} />
          </Routes>
        </div>
      </div>
    </>
  );
};

export default UserB;
