import React, { useEffect, useState } from "react";
import LoadingSpinner from "../../../../LoadingSpinner/LoadingSpinner";
import Filter from "../../../Filter";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { FaPlus, FaSearch } from "react-icons/fa";
import instance from "../../../../axios";
import { useDebounce } from "use-debounce";
import Pagination from "../../../Pagination";
import ErrorModal from "../../../../LoadingSpinner/ErrorModal";

const FreeLancers = () => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(30);
  const [count, setCount] = useState();
  const [freeLancers, setFreeLancers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [specialities, setSpecialities] = useState([]);
  const [filterOpen, setFilterOpen] = useState(false);
  const [speciality, setSpeciality] = useState();
  const [subSpeciality, setSubSpeciality] = useState();
  const [searchName, setSearchName] = useState();
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const params = {
          page,
          limit,
          sort,
          search,
          speciality,
          subSpeciality
        };
        const freelancerResponse = await instance.get(`/freelancer`, {
          params,
        });
        const specialityResponse = await instance.get(`/speciality/`);

        setFreeLancers(freelancerResponse.data.freelancers);
        setCount(freelancerResponse.data.count);
        setSpecialities(specialityResponse.data.specialities);
      } catch (error) {
        if (error.response) {
          setError({ type: "error", message: error.response.data.err });
        } else {
          setError({ type: "error", message: error.message });
        }
      } finally {
        setIsLoading(false);
      }
    })();
  }, [page, limit, search, sort, speciality, subSpeciality]);

  return isLoading ? (
    <LoadingSpinner asOverlay />
  ) : (
    <>
      <ErrorModal message={error} onClear={() => setError(null)} />
      <Filter
        filterOpen={filterOpen}
        setFilterOpen={setFilterOpen}
        clear={() => setSpeciality(null)}
      >
        <select
          id="speciality"
          name="speciality"
          className="w-full"
          value={speciality}
          onChange={(e) => {
            setSpeciality(e.target.value);
          }}
        >
          <option selected disabled value="" className="text-secondary">
            Speciality
          </option>
          {specialities.map((speciality, index) => (
            <option key={index} value={speciality.main}>
              {speciality.main}
            </option>
          ))}
        </select>
        {speciality && speciality !== "" && (
          <select
            id="subSpeciality"
            name="subSpeciality"
            className="w-full"
            value={subSpeciality}
            onChange={(e) => {
              setSubSpeciality(e.target.value);
            }}
          >
            <option selected disabled value="" className="text-secondary">
              Sub Speciality
            </option>
            {specialities
              .find((s) => s.main === speciality)
              ?.sub.map((sub) => (
                <option key={sub?._id} value={sub?._id}>
                  {sub.sub_speciality}
                </option>
              ))}
          </select>
        )}
      </Filter>
      <div
        className={`transition-all min-h-[calc(100vh-65px)] ${
          filterOpen ? "ml-44" : "ml-16"
        } py-4 flex flex-col space-y-2`}
      >
        <div className="flex justify-between items-center my-8">
          <h1 className="text-2xl">Freelancers</h1>
          {/* <div className="">FILTERS</div> */}
        </div>
        <div className="py-4 rounded-sm">
          <div className="flex justify-between items-center mb-2">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                setSearch(e.target.search.value);
              }}
              className="w-1/3 relative"
            >
              <button type="submit" className="absolute top-3 right-3">
                <FaSearch />
              </button>
              <input
                name="search"
                type="text"
                value={searchName}
                onChange={(e) => setSearchName(e.target.value)}
                placeholder={"Search By Name or ID"}
              />
            </form>

            <button
              className="inline-flex items-center rounded-md border px-3 py-2 text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
              type="button"
              onClick={() => navigate("/addfreeLancer")}
            >
              <FaPlus className="mr-2" />
              Add New Freelancer
            </button>
          </div>

          <div className="flex justify-end mb-2">
            <select
              id="role"
              name="role"
              className="w-1/4 focus:ring-0 focus:border-black"
              value={sort}
              onChange={(e) => {
                setSort(e.target.value);
              }}
            >
              <option selected disabled value="" className="text-secondary">
                Sort By
              </option>
              <option value="completed">Completed Tasks</option>
              <option value="profit">Profit</option>
            </select>
          </div>

          <table className="transition-all table drop-shadow">
            <thead>
              <tr className="text-center">
                <th>
                  <p className="text-blue-500 m-0">Freelancer Name</p>
                </th>
                <th>
                  <p className="text-blue-500 m-0">Speciality</p>
                </th>
                <th>
                  <p className="text-blue-500 m-0">Task Count</p>
                </th>
                <th>
                  <p className="text-blue-500 m-0">Completed Tasks</p>
                </th>
                <th>
                  <p className="text-blue-500 m-0">Total Cost</p>
                </th>
                <th>
                  <p className="text-blue-500 m-0">Total Profit</p>
                </th>
              </tr>
            </thead>
            <tbody className="text-center">
              {freeLancers && freeLancers.length > 0 ? (
                <>
                  {freeLancers.map((freelancer) => (
                    <tr key={freelancer?._id}>
                      <td>
                        <Link
                          className="text-black no-underline hover:underline"
                          to={`/freeLancer/${freelancer?._id}`}
                        >
                          {freelancer.freelancername}
                        </Link>
                      </td>
                      <td>
                        {freelancer.speciality &&
                          freelancer.speciality.map((s) => (
                            <div>{s.sub_speciality}</div>
                          ))}
                      </td>
                      <td>{freelancer.tasksCount}</td>
                      <td>{freelancer.completedCount}</td>
                      <td>{freelancer.totalGain}</td>
                      <td>{freelancer.totalProfit}</td>
                    </tr>
                  ))}
                  <tr>
                    <td colSpan="6">
                      <Pagination
                        currentPage={page}
                        totalPages={Math.ceil(count / limit)}
                        onNextPage={() => setPage(page + 1)}
                        onPreviousPage={() => setPage(page - 1)}
                        onFirstPage={() => setPage(1)}
                        onLastPage={() => setPage(Math.ceil(count / limit))}
                      />
                    </td>
                  </tr>
                </>
              ) : (
                <tr>
                  <td colSpan="6">There Is No Users</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default FreeLancers;
