import React, { useEffect, useRef, useState } from "react";
import axios from "../../../../../axios";
import LoadingSpinner from "../../../../../LoadingSpinner/LoadingSpinner";
import { emitTaskNotification } from "../../../../../util/io";
import FileListTable from "../../../../fileListTable";

const Ongoing = ({ taskId, freelancer, setStatus, setTask, task }) => {
  const [isLoading, setIsLoading] = useState(false);
  // const [file, setFile] = useState(null);

  const [attachment, setAttachment] = useState();
  const [descriptions, setDescriptions] = useState({});
  const attachmentRef = useRef();
  const handleFileChange = (file) => {
    setAttachment(file);
  };

  const handleDone = async () => {
    try {
      setIsLoading(true);
      if (!attachment) {
        alert("Please select a file");
      } else {
        const formData = new FormData();
        for (let i = 0; i < attachment.length; i++) {
          const f = attachment[i];
          const fileName = f.name;
          const fileDescription = descriptions[f.name];
          const encodedFilename = encodeURIComponent(fileName);
          formData.append(`attachmentDescription_${f.name}`, fileDescription);
          formData.append("file", f, encodedFilename);
        }
        await axios.post(`/task/action/done/${taskId}`, formData);
        setStatus("in review");
        emitTaskNotification(
          `Task No: ${task.serialNumber} - Status Changed From Ongoing To In Review`,
          task
        );
      }
      setIsLoading(false);
      window.location.reload();
    } catch (error) {
      setIsLoading(false);
      if (error.response) {
        console.log(error.response.data);
      } else {
        console.log(error);
      }
    }
  };

  return isLoading ? (
    <div className="flex items-center justify-between">
      <LoadingSpinner />
    </div>
  ) : (
    <div className="flex flex-col">
      <div className="flex items-end justify-between">
        <label htmlFor="attatchment">
          <span className="text-gray-400">Attatchment</span>
          <input
            ref={attachmentRef}
            type="file"
            multiple
            id="attachment"
            name="attachment"
            onChange={(event) => handleFileChange(event.target.files)}
          />
        </label>
        <button
          onClick={handleDone}
          className="bg-blue-500 text-white px-4 py-2 rounded active:scale-95"
        >
          Done
        </button>
      </div>

      <div>
        <FileListTable attachment={attachment} descriptions={descriptions} setDescriptions={setDescriptions} />
      </div>
    </div>
  );
};

export default Ongoing;
