import React, { useEffect, useState } from "react";
import axios from "axios";
import LoadingSpinner from "../../../../LoadingSpinner/LoadingSpinner";
import { useNavigate } from "react-router-dom";
import Filter from "../../../Filter.js";
import User from "../../../User.js";
import { FaPlus, FaSearch } from "react-icons/fa";
import Pagination from "../../../Pagination.js";
import instance from "../../../../axios.js";
import { useDebounce } from "use-debounce";
import ErrorModal from "../../../../LoadingSpinner/ErrorModal.js";

const Users = () => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(30);
  const [count, setCount] = useState();
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [searchName, setSearchName] = useState(null);
  const [search, setSearch] = useState(null);
  const [sort, setSort] = useState(null);
  const [filterRole, setFilterRole] = useState(null);
  const [filterOpen, setFilterOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const res = await instance.get(`/user`, {
          params: { page, limit, sort, role: filterRole, search },
        });
        setUsers(res.data.users);
        setCount(res.data.count);
        setIsLoading(false);
      } catch (error) {
        if (error.response) {
          setError({ type: "error", message: error.response.data.err });
        } else {
          setError({ type: "error", message: error.message });
        }
      } finally {
        setIsLoading(false);
      }
    })();
  }, [page, limit, search, filterRole, sort]);

  const deleteUserHandler = async (id) => {
    setIsLoading(true);
    try {
      setError(null);
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}:5001/api/user/${id}`
      );
      const responseData = await response;
      setError({ type: "success", message: responseData.data.message });
      setIsLoading(false);
      window.location.reload();
    } catch (err) {
      if (err.response) {
        setError({ type: "error", message: err.response.data.err });
      } else {
        setError({ type: "error", message: err.message });
      }
      setIsLoading(false);
    }
  };

  useEffect(
    () =>
      console.log({
        page,
        limit,
        filter: filterRole,
        search,
        sort,
      }),
    [page, limit, filterRole, search, sort]
  );

  return isLoading ? (
    <LoadingSpinner asOverlay />
  ) : (
    <>
      <ErrorModal error={error} onClear={() => setError(null)} />
      <Filter
        filterOpen={filterOpen}
        setFilterOpen={setFilterOpen}
        clear={() => setFilterRole(null)}
      >
        <select
          id="role"
          name="role"
          className="w-full focus:ring-0 focus:border-black"
          value={filterRole}
          onChange={(e) => {
            setFilterRole(e.target.value);
          }}
        >
          <option selected disabled value="" className="text-secondary">
            Select Role
          </option>
          <option value="admin">Admin</option>
          <option value="customerService">Customer Service</option>
          <option value="specialistService">Specialist Service</option>
          <option value="academicReviewer">Academic Reviewer</option>
        </select>
      </Filter>
      <div
        className={`transition-all min-h-[calc(100vh-65px)] ${
          filterOpen ? "ml-44" : "ml-16"
        } py-4 flex flex-col space-y-2`}
      >
        <div className="flex justify-between items-center my-8">
          <h1 className="text-2xl">System Users</h1>
          {/* <div className="">FILTERS</div> */}
        </div>
        <div className="bg-gray-100 px-8 py-4 rounded-sm drop-shadow">
          <div className="flex justify-between items-center">Team Members</div>
          <div className="flex justify-between items-center my-4">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                setSearch(e.target.search.value);
              }}
              className="w-1/3 relative"
            >
              <button type="submit" className="absolute top-3 right-3">
                <FaSearch />
              </button>
              <input
                name="search"
                type="text"
                value={searchName}
                onChange={(e) => setSearchName(e.target.value)}
                placeholder={"Search By Name or ID"}
              />
            </form>

            <button
              className="inline-flex items-center rounded-md border px-3 py-2 text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
              type="button"
              onClick={() => navigate("/adduser")}
            >
              <FaPlus className="mr-2" />
              Add New User
            </button>
          </div>

          <div className="flex justify-end">
            <select
              id="sort"
              name="sort"
              className="w-1/5 ml-auto focus:ring-0 focus:border-black"
              value={sort}
              onChange={(e) => {
                setSort(e.target.value);
              }}
            >
              <option selected disabled value="" className="text-secondary">
                Sort by
              </option>
              <option value="profit">Profit</option>
              <option value="completed">Completed</option>
            </select>
          </div>

          <div className="bg-white my-2 drop-shadow px-4 py-2 flex items-center justify-between">
            <div className="flex items-center text-gray-900 text-l font-bold space-x-4 w-2/6 text-center">
                User Name
            </div>
            <div className="w-2/6 flex text-gray-900 text-l font-bold justify-center">
                Role
            </div>
            <div className="w-2/6 flex items-center justify-center space-x-4">
              <p className="m-1 w-1/3 text-gray-900 text-l font-bold text-center">
                Tasks
              </p>
              <p className="m-1 w-1/3 text-gray-900 text-l font-bold text-center">
                Completed
              </p>
              <p className="m-1 w-1/3 text-gray-900 text-l font-bold text-center">
                Profit %
              </p>
            </div>
            <div className="w-1/6 flex items-center justify-end space-x-4">
            </div>
          </div>

          {users && users.length > 0 ? (
            <>
              {users.map((user) => (
                <User
                  key={user?._id}
                  deleteUserHandler={deleteUserHandler}
                  user={user}
                />
              ))}
              <Pagination
                currentPage={page}
                totalPages={Math.ceil(count / limit)}
                onNextPage={() => setPage(page + 1)}
                onPreviousPage={() => setPage(page - 1)}
                onFirstPage={() => setPage(1)}
                onLastPage={() => setPage(Math.ceil(count / limit))}
              />
            </>
          ) : (
            <div className="">
              <h2>There Is No Users</h2>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Users;
